import React, { useState, useEffect, useContext } from "react";
import image from "../public/texture2.png";
import "./Header.css";
import icon1 from "../header/public/svgexport-10.svg";
import icon2 from "../header/public/svgexport-11.svg";
import icon3 from "../header/public/svgexport-9.svg";
import TransactionIcon from "../header/public/Transaction.svg";
import HeaderPicture from "../header/public/ra4 1.png";
import car1 from "../header/public/Cars Picture for Card/6469-248d3ad036_LE_auto_x2 1.png";
import car2 from "../header/public/Cars Picture for Card/accent_1 1.png";
import car3 from "../header/public/Cars Picture for Card/Hyundai_Santa_Fe_White_background_531602_1600x1200 1.png";
import car4 from "../header/public/Cars Picture for Card/pars.png";
import car5 from "../header/public/Cars Picture for Card/images 1.png";
import car6 from "../header/public/Cars Picture for Card/hyundai_sonata_vi_sedan_1_LE_auto_x2 1.png";
import menu from "../header/public/menu.png";
import LoginRegister from "../Login/LoginRegister";
import { Carousel, message, Skeleton } from "antd";
import { StateApp } from "../context/Context";
import Navbar from "./Navbar";
import SuccessLogin from "../Modal/Success Login";
import { useDispatch, useSelector } from "react-redux";
import userInformation from "../slices/UserInformation";
import Submenu from "../profile/Submenu";
import { BiChevronDown } from "react-icons/bi";
import styled from "styled-components";
import { motion } from 'framer-motion';
import Modal from "react-modal";
import { LuMenu } from "react-icons/lu";
import logo from "../public/logo128.png";
import logo1 from "../public/maranco.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Link from "antd/es/typography/Link";
import { GiSteeringWheel } from "react-icons/gi";

Modal.setAppElement('#root');

const data = [
    { image: car1 },
    { image: car2 },
    { image: car3 },
    { image: car4 },
    { image: car5 },
    { image: car6 },
    { image: car6 },
    { image: car6 },
    { image: car3 },
    { image: car2 },
    { image: car1 },
    { image: car5 },
];


const responsiveSettings = (currentSlide, setCurrentSlide) => ({
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: Math.min(6, data.length),
    slidesToScroll: Math.min(6, data.length),
    initialSlide: currentSlide,
    swipeToSlide: true,
    draggable: true,
    beforeChange: (oldIndex, newIndex) => {
        setTimeout(() => {
            setCurrentSlide(newIndex);
        }, 1000);
    },
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: Math.min(3, data.length),
                slidesToScroll: Math.min(3, data.length),
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: Math.min(2, data.length),
                slidesToScroll: Math.min(2, data.length),
                initialSlide: currentSlide,
                swipeToSlide: true,
                draggable: true,
                beforeChange: (oldIndex, newIndex) => {
                    setTimeout(() => {
                        setCurrentSlide(newIndex);
                    }, 1000);
                },
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: currentSlide,
                swipeToSlide: true,
                draggable: true,
                beforeChange: (oldIndex, newIndex) => {
                    setTimeout(() => {
                        setCurrentSlide(newIndex);
                    }, 1000);
                },
            },
        },
    ],
});

const Header = () => {
    const {
        ShowNavbar,
        setShowNavbar,
        openLogin,
        setOpenlogin,
        showSuccessLogin,
        setshowSuccsessLogin,
        userMobile,
        getAccessToken,
    } = useContext(StateApp);

    const [selectedImage, setSelectedImage] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [animateImage, setAnimateImage] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
    const [carName, setCarName] = useState("");

    let navigate = useNavigate(null)

    const CarouselWrapper = styled(Carousel)`
        > .slick-dots li button {
            color: white;
            height: 6px;
            opacity: 1;
            background: #9d9d9d;
        }

        > .slick-dots li.slick-active button {
            background: #FFB800;
            color: #ffffff;
            z-index: 0 !important;
        }

        > .slick-dots li button:before {
            display: none;
        }

        > .slick-dots {
            bottom: -12px !important;
            z-index: 0 !important;
        }
    `;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subMenu, setSubmenu] = useState(false);
    const [carInfo, setCarInfo] = useState([]);


    const showSubmenu = () => {
        setSubmenu(!subMenu);
    };

    const showModal = () => {
        setIsModalOpen(true);
        setOpenlogin(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleNavbar = () => {
        setShowNavbar(true);
    };

    const { userInfo, error } = useSelector((state) => state.userinformation);
    const { name, idCode, birthDate, gender, address, status, accessToken, mobile, loginInfo } = userInfo;
    const [token, setToken] = useState();
    const [wheelAnimation, setWheelAnimation] = useState(false);

    useEffect(() => {
        setToken(accessToken);
    }, [accessToken , token]);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px 0px 0px 0px',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            borderRadius: '10px',
            outline: 'none',
            border: 'none'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
    };

    useEffect(() => {
        axios.get(`https://taxi-end.liara.run/cars/v1/get-car-list-without-paginate`).then(res => {
            setCarInfo(res.data.data);
            setCarName("تویوتا کمری")
            setSelectedImage(`https://taxi-end.liara.run/${res.data.data[0].photo.fileName}`);
        }).catch(err => {
            if (err.status !== 200)
                message.error("خطا !‌ دوباره امتحان کنید .")
            if (err?.message === "Network Error")
                message.error("خطا در اتصال به اینترنت !")

        })
    }, []);

    const getTheFileName = (fileName) => {
        return `https://taxi-end.liara.run/${fileName}`;
    }
    const getFileNameAndCarName = (imgFile, carName) => {
        setSelectedImage(getTheFileName(imgFile))
        setCarName(carName)
    }

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCancel}
                style={customStyles}
                className="modalMobile"
            >
                <button className="custom-modal-close" onClick={handleCancel}>
                    &times;
                </button>
                {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : null}
            </Modal>

            <div className="Header" style={{ backgroundImage: `url(${image})` }}>

                <div>
                    {/* Nav menu */}
                    <div className="md:hidden flex justify-between mr-6 z-10">
                        <ul className="HeaderList my-[32px] flex items-center !mr-0">
                            <li className="cursor-pointer">
                                <div>
                                    <img className="w-[64px]" src={logo} />
                                </div>
                            </li>
                            <li className="cursor-pointer text-nowrap"><a href="https://marankoo.ir/">صفحه اصلی</a></li>
                            {/* <li className="cursor-pointer">تماس با ما</li> */}
                            <li className="cursor-pointer"><a href="https://marankoo.ir/blog/">بلاگ</a></li>
                            <li className="cursor-pointer text-nowrap"><a href="/rules">قوانین</a></li>
                            <li className="cursor-pointer text-nowrap"><a href="https://marankoo.ir/about-us/">درباره ما</a></li>
                        </ul>

                        <ul className="select-none items-center cursor-pointer HeaderList col-span-1 justify-end gap-2 my-[32px] ">
                            <li onClick={() => navigate("/transactions")} className="flex text-nowrap gap-[16px] ml-[10px]">
                                <img src={TransactionIcon} alt="icon1" className="HeaderIcons" />
                                تراکنش ها
                            </li>
                            <li onClick={() => navigate("/support")} className="flex text-nowrap gap-[16px] ml-[10px]">
                                <img src={icon1} alt="icon1" className="HeaderIcons" />
                                پشتیبانی
                            </li>
                            <li onClick={() => navigate("/passengersTrips")} className="flex text-nowrap gap-[16px] ml-[10px]">
                                <img src={icon2} alt="icon1" className="HeaderIcons" />
                                سفرهای شما
                            </li>
                            <div className={`${loginInfo ? '' : 'hidden'} z-10`}>
                                {!token ? (
                                    <div>
                                        <li onClick={showModal} className="flex gap-[16px] ml-[10px] ">
                                            <img src={icon3} alt="icon1" className="HeaderIcons" />
                                            <p> ورود به حساب کاربری </p>
                                        </li>
                                    </div>
                                ) : (
                                    <div className="relative">
                                        <li onClick={showSubmenu} className="flex gap-[16px] ml-[32px] items-center">
                                            <img src={icon3} alt="icon1" className="HeaderIcons" />
                                            <div className="flex gap-2 items-center">
                                                <p className="text-[16px] text-center justify-self-center">
                                                    {name ? name : mobile}
                                                </p>
                                                <BiChevronDown
                                                    style={{ fontSize: "32px" }}
                                                    className={`transform transition-transform duration-300 ${subMenu ? 'rotate-180' : 'rotate-0'
                                                        }`}
                                                />
                                            </div>
                                        </li>
                                        {subMenu && (
                                            <div className="absolute left-2 w-[220px]">
                                                <div className="slide-down translate-y-6 transform-gpu">
                                                    <Submenu />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </ul>
                    </div>
                    {/* Text And Picture For Header */}
                    {/* Mobile Mood */}
                    <div className="Handle">
                        <div className="flex justify-center pt-5">
                            <img className="w-[128px]" src={logo1} />
                        </div>
                        <div className="absolute top-24">
                            <h2 className="text-[22px] text-white font-[500] mx-6 md:mt-10 text-center">
                                خودرو {carName} مناسب سفر های شما
                            </h2>

                        </div>
                        {selectedImage && (
                            <div className="col-span-1 flex justify-center">
                                {isDesktop ? (
                                    <motion.img
                                        src={selectedImage}
                                        alt="SelectedImage"
                                        className="top-[15rem] absolute justify-self-center"
                                        initial={{ x: -window.innerWidth }}
                                        animate={{ x: 0 }}
                                        transition={{ duration: 1 }}
                                        key={selectedImage}
                                    />
                                ) : (
                                    <img
                                        src={selectedImage}
                                        alt="SelectedImage"
                                        className="top-[15rem] absolute justify-self-center w-[97%]"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    {/* Mobile Mood */}
                    <div className="md:hidden grid grid-cols-2">
                        <div className="col-span-1">
                            {
                                carName ? (
                                    <h2 className="text-[32px] text-white font-[900] mx-[60px] my-6">
                                        خودرو {carName} مناسب سفر های شما
                                    </h2>
                                ) : (
                                    <Skeleton />
                                )
                            }
                        </div>

                        {selectedImage && (
                            <div className="col-span-1 ">
                                {isDesktop ? (
                                    <motion.img
                                        src={selectedImage}
                                        alt="SelectedImage"
                                        className="top-[165px] w-[50%] max-w-[600px] absolute justify-self-end"
                                        initial={{ x: -window.innerWidth }} // Start position: off-screen left
                                        animate={{ x: 0 }} // End position: normal position
                                        transition={{ duration: 0.7 }} // Animation duration (1 second)
                                        key={selectedImage} // اضافه کردن یک کلید برای انیمیشن تا هر بار که تغییر می‌کند، انیمیشن اجرا شود
                                    />
                                ) : (
                                    <img
                                        src={selectedImage}
                                        alt="SelectedImage"
                                        className="top-[165px] absolute justify-self-end min-w-[300px]"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-[120px] pl-20 pr-20 md:pl-10 md:pr-10">
                <CarouselWrapper autoplay {...responsiveSettings(currentSlide, setCurrentSlide)}>
                    {carInfo.map((item, index) => (
                        <div key={index} className="flex justify-center items-center">
                            <div
                                className="cursor-pointer md:w-[98%] relative w-[90%] h-[104px] rounded-[20px] bg-[#FFB800] mx-auto"
                                onClick={() => getFileNameAndCarName(item.photo.fileName, item.name)}
                            >
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <img src={getTheFileName(item.photo.fileName)} alt={`Car ${index}`} />
                                </div>
                            </div>
                        </div>
                    ))}
                </CarouselWrapper>
            </div>
        </>
    );
};

export default Header;
