import React, { useEffect, useContext, useRef, useState } from 'react'
import Header2 from '../header/Header2'
import Footer from '../footer/Footer'
import axios from 'axios'
import { url } from '../url'
import { FaCross, FaTicketAlt } from 'react-icons/fa'
import 'moment-jalaali';
import { message, notification } from "antd";
import Modal from "react-modal";
import { useSelector } from 'react-redux'
//icons
import { FaUser } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import Loading from '../Loading/Loading'
import { TbLoader2 } from "react-icons/tb";
import carMapic from '../TripPage/public/car-service-svgrepo-com.svg'
import { StateApp } from '../context/Context';
import { useNavigate } from 'react-router-dom';
import LoginRegister from '../Login/LoginRegister';
import SuccessLogin from '../Modal/Success Login';
import { GiCarWheel } from "react-icons/gi";


const UserTickets = () => {
  const [openTicket, setOpenTicket] = useState(false)
  const mom = require("moment-jalaali");
  const [userTickets, setUserTickets] = useState([]);
  const [saveId, setSaveId] = useState("");
  const [ticketResponse, setTicketResponse] = useState([])
  const [subject, setSubject] = useState("");
  const [newPassengerResponse, setNewPassengerResponse] = useState("");
  const [specificTicketId, setSpecificTicketId] = useState("");
  const [updateMessages, setUpadateMessages] = useState(false);
  const [ticketStatus, setTicketStatus] = useState(null)
  const [loadTickets, setLoadTickets] = useState(true)
  const [noToken, setNoToken] = useState(false)
  const [noTickets, setnoTickets] = useState(false)
  const [noInternet, setNoInternet] = useState(false)
  const [reloadAnimation, setReloadAnimation] = useState(false);
  const [apiRecall, setApiRecall] = useState(false);

  const tryAgain = () => {
    setTimeout(() => {
      setApiRecall(prev => !prev)
      setReloadAnimation(false);
    }, 3000);
    setReloadAnimation(true);
  }
  // modal
  const { openLogin, showSuccessLogin } = useContext(StateApp);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      outline: 'none',
      border: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };

  const navigate = useNavigate(null)

  useEffect(() => {
    axios.get(`${url}/tickets/v1/get-my-ticket-list-passenger`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(res => {
      setUserTickets(res.data.data.docs)
      setNoToken(false)
      setNoInternet(false)
      setLoadTickets(false)
    }).catch(err => {
      setLoadTickets(true)
      if (err?.response?.status === 404) {
        setnoTickets(true)
        setLoadTickets(false)
        setNoInternet(false)
      }
      if (err?.response?.status === 401) {
        notification.error({
          message: err?.response?.data?.data === "شما وارد حساب کاربری خود نشده اید !"
        })
        setNoToken(true)
        setNoInternet(false)
      }
      if (err.message === "Network Error") {
        setNoInternet(true)
      }
    })
  }, [apiRecall])

  const finalList = [...userTickets].reverse();

  const getTicketMessages = () => {
    axios.get(`${url}/tickets/v1/get-ticket-messages-passenger/${specificTicketId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then(res => {
      setOpenTicket(true)
      setTicketResponse(res.data.data)
    }).catch(err => {
      notification.error({
        message: "خطا ! لطفا بعدا امتحان کنید !",
        description: "",
        placement: 'topRight',
      });
    })
  }
  const messagesBox = useRef(null);

  useEffect(() => {
    if (messagesBox.current) {
      messagesBox.current.scrollTop = messagesBox.current.scrollHeight;
    }
  }, []);

  const goToTop = () => {
    if (messagesBox.current) {
      messagesBox.current.scrollTop = messagesBox.current.scrollHeight;
    }
  };
  useEffect(() => {
    if (specificTicketId !== "")
      getTicketMessages()
  }, [updateMessages, specificTicketId])

  const sendNewResponse = (ticketId) => {
    axios.put(`${url}/tickets/v1/passenger-answer-ticket/${ticketId}`, {
      description: newPassengerResponse
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then(res => {
      setUpadateMessages(pre => !pre)
      setNewPassengerResponse("")
    }).catch(err => {
      notification.error({
        message: "خطا ! لطفا دوباره امتحان کنید...",
        description: "",
        placement: 'topRight',
      });
    })
  }

  const ticketStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      backgroundColor: "white",
      height: "80%",
      maxHieght: "700px",
      width: "80%",
      maxWidth: "1400px",
      outline: 'none',
      border: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };

  return (
    <div className='w-screen h-screen flex flex-col'>
      <div className='w-full md:hidden'>
        <Header2 />
      </div>

      <div className=' flex flex-col items-center justify-center '>
        <div className=''>
          <h2 className={`text-[25px] font-bold my-10 ${loadTickets || noToken || noTickets ? "hidden" : "block"}`}>تیکت های من</h2>
        </div>

        {
          noInternet ? (
            <>
              <div className='flex items-center justify-center py-10 px-5'>
                <div className='flex items-center gap-5 w-full flex-col'>
                  <div className='errorSign bg-red-600 flex items-center justify-center'>
                    <div className='errorSign2 bg-red-100 text-red-600 text-[84px] flex items-center justify-center'>!</div>
                  </div>
                  <p className='text-red-600 text-center'>خطا در برقراری ارتباط! <br className='md:block hidden' /> لطفا اینترنت خود را بررسی کنید...</p>
                  <div className={` ${reloadAnimation ? "flex items-center gap-x-2" : "invisible"}`}>
                    <p>منتظر بمانید...</p>
                    <GiCarWheel className={`${reloadAnimation && "animate-spin text-[25px]"}`} />
                  </div>
                  <button className=' text-[18px] border-2 border-darkBlue text-darkBlue hover:bg-darkBlue hover:text-white rounded-lg py-2 px-4 mt-5' onClick={tryAgain}>تلاش مجدد</button>
                </div>
              </div>
            </>
          ) : (
            <div id='ticketHolder' className={`flex flex-col rounded-lg w-[80%] lg:w-[96%] max-w-[1300px] gap-y-4 overflow-auto p-10`}>
              {
                noToken ? (
                  <div className='w-full h-full flex items-center justify-center md:flex-col'>
                    <img src={carMapic} />
                    <div className='flex gap-2 flex-col mr-5 md:mt-5'>
                      <p className='text-[20px] mb-5 text-center'>شما وارد حساب کاربری خود نشده اید !</p>
                      <button onClick={() => setIsModalOpen(true)} className='bg-darkBlue px-4 py-2 text-white rounded-lg cursor-pointer'>ورود به حساب کاربری</button>
                      <button className='border-2 border-darkBlue px-4 py-2 text-darkBlue rounded-lg cursor-pointer' onClick={() => navigate('/')}>بازگشت به صفحه اصلی</button>
                    </div>
                  </div>
                ) : (
                  <>
                    {
                      loadTickets ? (
                        <div className='w-full flex justify-center items-center'>
                          <div className='flex items-center gap-x-5'>
                            <p className='text-gray-700'>لطفا منتظر بمانید</p>
                            <TbLoader2 className='animate-spin text-slate-700' />
                          </div>
                        </div>
                      ) : (
                        <>
                          {
                            finalList.length > 0 ? (
                              <>
                                {
                                  finalList.map((item, index) => (
                                    <div dir='ltr' key={index} className='grid grid-cols-3 lg:bg-slate-100 p-10 px-5 rounded-bl-[50px] lg:rounded-none border-b-2 border-slate-600
                                       rounded-tl-[50px] bg-white hover:drop-shadow-lg duration-[0.4s] cursor-pointer hover:text-[18px] hover:py-12 hover:px-7'
                                      onClick={() => {
                                        setSpecificTicketId(item?._id);
                                        setSubject(item?.subject)
                                        setTicketStatus(item?.status)
                                      }}
                                    >

                                      <div className='flex items-center gap-x-5 md:col-span-3'>
                                        <div className='flex items-center gap-x-3'>
                                          <div className='bg-slate-600 text-white rounded-full w-[40px] h-[40px] flex justify-center items-center text-[24px]'><FaTicketAlt /></div>
                                        </div>
                                        <p title='موضوع تیکت' className='text-darkBlue text-nowrap'>{item.subject}</p>
                                      </div>

                                      <div className='flex items-center justify-center lg:text-slate-600 lg:mt-2'>
                                        <p>{mom(item.createdAt.slice(0, 10), 'YYYY/MM/DD').locale('fa').format('jYYYY/jM/jD')}</p>
                                      </div>

                                      <div className='flex items-center justify-center md:col-start-3 md:col-end-4'>
                                        <p>وضعیت  :‌ {item?.status === "open" ? <span className='text-green-600'>باز</span> : <span className='text-red-600'>بسته</span>}</p>
                                      </div>

                                    </div>
                                  ))
                                }
                              </>
                            ) : (
                              <div className='w-full h-full flex items-center justify-center gap-x-10 md:flex-col'>
                                <div className='bg-darkBlue rounded-full p-5'>
                                  <MdSupportAgent className='text-[65px] text-white' />
                                </div>

                                <div className='flex items-center flex-col gap-5 md:mt-5'>
                                  <p className='text-[24px]'>شما تیکت ثبت شده ای ندارید !</p>
                                  <button onClick={() => navigate('/support')} className='text-red-600 border-2 w-full border-red-600 duration-[0.2s] rounded-lg px-5 py-2 hover:bg-red-600 hover:text-white'>بازگشت</button>
                                </div>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                  </>
                )
              }
            </div>
          )
        }
      </div>


      {/* -------- modal -------- */}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        style={customStyles}
        className="modalMobile"
      >

        <div className=''>
          <button className="custom-modal-close" onClick={handleCancel}>
            &times;
          </button>
          {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : null}
        </div>
      </Modal>

      <Modal
        isOpen={openTicket}
        onRequestClose={openTicket}
        style={ticketStyle}
        className="modalMobile w-[700px] md:!w-full md:!h-full md:!rounded-none"
      >

        <div id='ticketAnswerPage' className='flex flex-col p-2 h-full justify-between'>
          <button className="text-[25px] text-right pr-2" onClick={() => {
            setOpenTicket(false)
            setSpecificTicketId("")
          }}>
            &times;
          </button>
          <div id='ticketAnswerPage' className='md:h-full md:pb-[7rem] overflow-auto'>


            {/* messages container  */}

            <div ref={messagesBox} id='ticketAnswerPage' className='overflow-y-auto md:h-full md:relative'>
              <div className='w-full px-10 overflow-y-auto'>
                {
                  ticketResponse.map((item) => (
                    <>
                      {
                        item.sender.passenger ? (
                          <div className='mt-5'>
                            <div className='flex items-center gap-x-2 text-[24px]'>
                              <FaUser className='text-[24px]' />
                              <p className='text-black'>شما</p>
                            </div>

                            <div className='h-full bg p-5 my-2 border-r-[1px] md:m-0 border-amber-500'>
                              <div className='flex flex-col justify-start'>
                                <p className=' break-words'><span className='text-cyan-800'></span>{item.description}</p>
                                <p className='text-gray-600 mt-2'>{mom(item.createdAt, "YYYY/MM/DD").locale("fa").format('jYYYY/jM/jD')}</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='mt-5'>
                            <div className='flex items-center justify-end gap-x-2 text-[24px]'>
                              <p className='text-black'>پشتیبانی</p>
                              <MdSupportAgent className='text-[30px]' />
                            </div>

                            <div className='flex justify-end'>
                              <div className='h-full bg p-5 my-5 md:m-0 flex justify-end  border-l-[1px] border-amber-500 w-full'>
                                <div className='flex flex-col justify-end w-fit '>

                                  {/* ------- تاریخ پیام پشتیبانی -------- */}
                                  <p className='text-right   w-full max-w-[400px] text-wrap'>{item.description}</p>
                                  <p className='text-gray-600 mt-2'>{mom(item.createdAt, "YYYY/MM/DD").locale("fa").format('jYYYY/jM/jD')}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </>
                  ))
                }
              </div>
            </div>
          </div>

          <div className='w-full flex items-center justify-center pb-6 md:absolute md:bottom-0'>
            {
              ticketStatus === "open" ? (
                <div className='w-[80%] bg-slate-400 rounded-full flex items-center px-3'>
                  <IoSend onClick={() => {
                    newPassengerResponse !== "" && sendNewResponse(specificTicketId);
                    goToTop()
                  }} className='flex-grow text-[25px] text-slate-800 cursor-pointer' />
                  <input value={newPassengerResponse} onChange={(e) => setNewPassengerResponse(e.target.value)} type='text' placeholder='پیام خود را بنویسید...'
                    className='bg-transparent md:border-none outline-none w-full placeholder:text-slate-700 h-[45px] pr-5'
                  />
                </div>
              ) :
                (
                  <div>
                    <p className='text-red-500 text-[20px]'>تیکت شما بسته شد !</p>
                  </div>
                )
            }
          </div>
        </div>

      </Modal>
    </div>
  )
}

export default UserTickets
