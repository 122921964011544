import React, { Component, useContext, useState } from "react";
import Loading from "../Loading/Loading";
import { StateApp } from "../context/Context";
import { useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import getProfile from "../profile/GetProfile";
import { AiOutlineMan, AiOutlineWoman } from "react-icons/ai";
import moment from 'moment-jalaali';
import userImg from "./public/user.svg";
import DatePicker from "react-multi-date-picker";
import { url } from "../url";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import persian_en from "react-date-object/locales/persian_en"
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";

const UpdateProfile = () => {
    const { loading, setLoading, } = useContext(StateApp);
    const { userInfo, error } = useSelector((state) => state.userinformation);
    const { name, idCode, birthDate, gender, address, status, accessToken, mobile, loadingProfile } = userInfo;
    const [nameValue, setNameValue] = useState(name);
    const [idCodeValue, setIdCodeValue] = useState(idCode);
    const [birthDateValue, setBirthDateValue] = useState(birthDate?.slice(0, 10));
    const [genderValue, setGenderValue] = useState(gender);
    const [addressValue, setAddressValue] = useState(address);
    const [selectGender, setSelectGender] = useState(false);
    const [openCalender, setOpenCalender] = useState(false);
    const [messageShown, setMessegeShowen] = useState(false);

    const handleUpdateProfile = () => {
        setLoading(true)
        axios.put(`${url}/passenger/v1/update-my-profile`, {
            full_name: nameValue,
            national_code: idCodeValue,
            birth_date: birthDateValue,
            gender: genderValue,
            address: addressValue,
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    message.success(response.data.message);
                    setInterval(() => {
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                    setLoading(false)
                } else {
                    console.error("Error:", error);
                }
                setLoading(false)
            });
    }

    const handleSelectGender = () => {
        setSelectGender(!selectGender);
    }

    const selectGenderOption = (gender) => {
        setGenderValue(gender);
        setSelectGender(false);
    }

    const handleOpenCalender = () => {
        setOpenCalender(!openCalender)
    }

    const [userProf, setUserProf] = useState("");

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first selected file
    };

    const handleDateChange = (date) => {
        const gregorianDate = date.convert(gregorian, gregorian_en).format("YYYY-MM-DD");
        setBirthDateValue(gregorianDate)
    };

    const convertToEnglishNumbers = (input) => {
        const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        const englishNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        return input?.replace(/[۰-۹]/g, (match) => englishNumbers[persianNumbers.indexOf(match)]);
    };

    return (
        <>
            <div className="transform-gpu bg-white rounded-[20px]">
                <div className=" md:w-full md:h-full w-[800px] top-[10px]"
                    style={{ fontFamily: "IRANSans" }}>
                    <div className="w-[100%] flex flex-col justify-center items-center">
                        <div className="mt-5">
                            <p className="text-[20px] my-8">
                                ثبت اطلاعات کاربر
                            </p>
                        </div>
                        

                        <div className="form flex flex-col justify-center items-center mt-6 md:!w-[100%]">
                            <div className="inputDiv">
                                <input
                                    type="text"
                                    id="ّFirstAndLast"
                                    className="form__input"
                                    autoComplete="off"
                                    placeholder=" "
                                    size={50}
                                    value={nameValue}
                                    onChange={(e) => setNameValue(e.target.value)}
                                />
                                <label htmlFor="ّFirstAndLast" className="form__label">
                                    نام و نام خانوادگی
                                </label>
                            </div>
                            <div className="inputDiv">
                                <input
                                    type="text"
                                    id="ID"
                                    className="form__input"
                                    autoComplete="off"
                                    placeholder=" "
                                    value={convertToEnglishNumbers(idCodeValue)}
                                    maxLength={10}
                                    onKeyPress={(e) => {
                                        const allowedChars = /[0-9\b ۰-۹\b]/;
                                        if (!allowedChars.test(e.key)) {
                                            if (!messageShown) {
                                                message.error("لطفا فقط عدد وارد کنید");
                                                setMessegeShowen(true);
                                                setTimeout(() => { setMessegeShowen(false) }, 3000); // 3 ثانیه
                                            }
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => setIdCodeValue(convertToEnglishNumbers(e.target.value))}
                                />
                                <label htmlFor="ID" className="form__label">
                                    کدملی
                                </label>
                            </div>
                            <div className="inputDiv">

                                <div
                                    id="birthdate"
                                    className="form__input"
                                    // onChange={(e) => setBirthDateValue(e.target.value)}
                                    onClick={handleOpenCalender}
                                >
                                    <DatePicker
                                        value={birthDate}
                                        style={{ width: "100%", height: "100%", border: "none", outline: "none" }}
                                        calendar={persian}
                                        locale={persian_fa}
                                        format="YYYY/MM/DD"
                                        
                                        onChange={(date) => {

                                            const formattedDate = date ? date.format("YYYY/MM/DD") : null;
                                            const englishDate = convertToEnglishNumbers(formattedDate);
                                            setBirthDateValue(englishDate);
                                            handleDateChange(date)
                                        }}
                                    />
                                    
                                </div>
                                <label htmlFor="birthdate" className="form__label">
                                    تاریخ تولد
                                </label>

                            </div>
                            <div className="inputDiv cursor-pointer" onClick={handleSelectGender}
                            >
                                <input
                                    type="text"
                                    id="gender"
                                    className="form__input"
                                    autoComplete="off"
                                    placeholder=" "
                                    value={genderValue === "male" ? "مرد" : "زن"}
                                    onChange={(e) => setGenderValue(e.target.value)}
                                    readOnly={true}
                                />
                                <label onClick={handleSelectGender}
                                    htmlFor="gender" className="form__label">
                                    جنسیت
                                </label>
                            </div>
                            {
                                selectGender && (
                                    <div
                                        className="slide-down translate-y-6 transform-gpu w-[80%] h-auto z-[1000] rounded-[10px] bg-gray-100 border-2 mt-[-24px] mb-8">
                                        <div className="flex flex-col p-2 gap-3">
                                            <div className="flex items-center gap-2 cursor-pointer"
                                                onClick={() => selectGenderOption('male')}>
                                                <p className="text-[18px]">مرد</p>
                                            </div>
                                            <div className="flex items-center gap-2 cursor-pointer"
                                                onClick={() => selectGenderOption('female')}>
                                                <p className="text-[18px]">زن</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="inputDiv ">
                                <input
                                    type="text"
                                    id="Address"
                                    className="form__input w-full !px-2"
                                    autoComplete="off"
                                    placeholder=" "
                                    value={addressValue}
                                    onChange={(e) => setAddressValue(e.target.value)}
                                />
                                <label htmlFor="Address" className="form__label">
                                    آدرس
                                </label>
                            </div>

                            <button
                                onClick={handleUpdateProfile}
                                type="submit"
                                className=" mb-10 flex justify-center flex-col items-center md:flex md:flex-col md:justify-center md:items-center bg-[#4F48EC] md:w-[80%] md:h-[40px] md:rounded-[10px] w-[80%] h-[48px] rounded-[10px]"
                            >
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <p style={{ fontFamily: "IRANSans" }}
                                        className="text-white text-[22px] md:text-[16px]">ثبت
                                        اطلاعات</p>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UpdateProfile;