import React, {useContext} from "react";
import picture from "../Login/public/picture.jpg";
import Login from "./Login";
import CreateAccount from "./CreateAccount";
import {StateApp} from "../context/Context";
import "./CreateAccount.css";

const LoginRegister = () => {
    const {Register, setRegister} = useContext(StateApp);

    const handleCreateAccountClick = () => {
        setRegister(!Register);
    };

    return (
        <div className=" rounded-[20px] md:w-full w-[700px]  grid grid-cols-10 bg-white md:grid-cols-1">
            <div
                className={`rounded-[20px]  md:grid-cols-1 relative  flex flex-col justify-center items-center col-span-7 bg-white z-10 `}
            >
                {Register ? <Login/> : <CreateAccount/>}
            </div>
            <div className="md:hidden col-span-3 flex justify-center items-center bg-transparent ">
                <img className="bg-transparent rounded-l-[20px]" src={picture}/>
            </div>
        </div>
    );
};

export default LoginRegister;
