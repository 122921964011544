import React, {useContext, useEffect, useState} from "react";
import image from "../public/texture1.png";
import icon1 from "./public/svgexport-10.svg";
import icon2 from "./public/svgexport-11.svg";
import icon3 from "./public/svgexport-9.svg";
import {Link, useNavigate} from "react-router-dom";
import {Modal} from "antd";
import LoginRegister from "../Login/LoginRegister";
import SuccessLogin from "../Modal/Success Login";
import {BiChevronDown} from "react-icons/bi";
import Submenu from "../profile/Submenu";
import {useSelector} from "react-redux";
import {StateApp} from "../context/Context";
import HeaderPicture from "./public/ra4 1.png";
import {Skeleton} from 'antd';
import logo from '../public/logo (2).png'
import TransactionIcon from "../header/public/Transaction.svg";

const Header2 = () => {


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subMenu, setSubmenu] = useState(false);

    const navigate = useNavigate()

    const showSubmenu = () => {
        setSubmenu(!subMenu);
    }
    const showModal = () => {
        setIsModalOpen(true);
        setOpenlogin(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        // this is for when user successfully logged in will and click on close button on modal
        window.location.reload();
        setIsModalOpen(false);
    };


    const {userInfo, error} = useSelector((state) => state.userinformation);
    const {name, accessToken, mobile, loginInfo} = userInfo;
    const [token, setToken] = useState()

    useEffect(() => {
        setToken(accessToken);
    }, [accessToken, token]);

    const {
        openLogin,
        setOpenlogin,
        showSuccessLogin,
    } = useContext(StateApp);

    const [selectedImage, setSelectedImage] = useState(HeaderPicture);
    const [triggerAnimation, setTriggerAnimation] = useState(true);
    useEffect(() => {
        setTriggerAnimation(true)
    }, [selectedImage]);

    const handlenavigate = () => {
        navigate("/")
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px 0px 0px 0px',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            borderRadius: '10px',
            outline: 'none',
            border: 'none'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
    };

    return (
        <>


            <div className="h-[82px] md:hidden items-center bg-cover" style={{backgroundImage: `url(${image})`}}>
                <div className="">
                    {/*Nav menu */}
                    <div className="md:hidden grid grid-cols-2 tablet10:grid-cols-12 h-[82px] ">
                        <ul className="HeaderList  tablet10:!text-[14px] col-span-1 tablet10:col-span-5  items-center">
                            <li>
                                <div className="">
                                    <img src={logo} className="w-[65px] h-fit"/>
                                </div>
                            </li>
                            <li className="cursor-pointer text-nowrap" onClick={handlenavigate}>
                                صفحه اصلی
                            </li>
                            {/* <li>
                                تماس با ما
                            </li> */}
                            <li className="cursor-pointer text-nowrap">
                                <a href="https://marankoo.ir/blog/">بلاگ</a>
                            </li>

                            <li className="cursor-pointer text-nowrap"><a href="/rules">قوانین</a></li>


                            <li className="cursor-pointer text-nowrap">
                                <a href="https://marankoo.ir/about-us/">درباره ما</a>
                            </li>
                        </ul>

                        <ul className=" tablet10:!text-[14px] select-none items-center tablet10:pl-[20px] pl-[20px] cursor-pointer HeaderList col-span-1 tablet10:col-span-7 justify-end  my-auto">
                            <li onClick={() => navigate("/transactions")}
                                className="flex text-nowrap gap-[16px] ml-[10px]">
                                <img src={TransactionIcon} alt="icon1" className="HeaderIcons"/>
                                تراکنش ها
                            </li>
                            <li onClick={() => navigate("/support")}
                                className="flex text-nowrap tablet10:gap-[5px] tablet10:ml-0 gap-[16px] ml-[32px]">
                                <img src={icon1} alt="icon1" className="HeaderIcons tablet10:!size-5"/>
                                پشتیبانی
                            </li>
                            <li onClick={() => navigate("/passengersTrips")}
                                className="flex text-nowrap tablet10:gap-[5px] tablet10:ml-0 gap-[16px] ml-[32px]">
                                <img src={icon2} alt="icon1" className="HeaderIcons tablet10:!size-5"/>
                                سفرهای شما
                            </li>
                            <div>
                                {loginInfo ? (<div>
                                    {!token ? (
                                        <div>
                                            <li onClick={showModal}
                                                className="flex tablet10:gap-[5px] tablet10:ml-0 gap-[16px] ml-[32px] ">
                                                <img src={icon3} alt="icon1" className="HeaderIcons tablet10:!size-5"/>
                                                <p className="text-nowrap"> ورود به حساب کاربری </p>
                                            </li>
                                        </div>
                                    ) : (
                                        <div className="relative">
                                            {/* passenger's username  */}
                                            <li onClick={showSubmenu}
                                                className="flex tablet10:gap-[5px] gap-[16px] ml-[10px] items-center">
                                                <img src={icon3} alt="icon1" className="HeaderIcons tablet10:!size-5"/>
                                                <div className="flex gap-2 items-center">
                                                    <p className="text-[16px] text-center justify-self-center">{name ? name : mobile}</p>
                                                    <BiChevronDown
                                                        style={{fontSize: "32px"}}
                                                        className={`transform transition-transform duration-300 ${subMenu ? 'rotate-180' : 'rotate-0'}`}
                                                    />
                                                </div>
                                            </li>
                                            {subMenu && (
                                                <>
                                                    <div className="absolute w-[220px] left-2">
                                                        <div className="slide-down translate-y-6 transform-gpu">
                                                            <Submenu/>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}

                                </div>) : (
                                    <div>
                                        <Skeleton.Input
                                            active
                                            style={{height: "30px", width: "auto"}}
                                        />
                                    </div>)}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>


            {/*<Modal*/}
            {/*    style={{width: "700px", overflow: "hidden"}}*/}
            {/*    footer={null}*/}
            {/*    mask={true}*/}
            {/*    open={isModalOpen}*/}
            {/*    onOk={handleOk}*/}
            {/*    closable={false}*/}
            {/*    className="modalMobile"*/}
            {/*    onRequestClose={handleCancel}*/}
            {/*>*/}
            {/*    <button className="custom-modal-close" onClick={handleCancel}>*/}
            {/*        &times;*/}
            {/*    </button>*/}
            {/*    {openLogin ? <LoginRegister/> : showSuccessLogin ? <SuccessLogin/> : null}*/}
            {/*</Modal>*/}

            {isModalOpen && <ModalLogin openLogin={openLogin} showSuccessLogin={showSuccessLogin} setIsModalOpen={setIsModalOpen}/> }

        </>
    );
}


export function ModalLogin({openLogin, showSuccessLogin, setIsModalOpen}) {

    const handleCancel = () => {
        // this is for when user successfully logged in will and click on close button on modal
        window.location.reload();
        setIsModalOpen(false);
    };

    return (

        <div className="report-modal fixed inset-0 bg-black bg-opacity-80 transition-opacity">
            <div className="flex min-h-full p-3">
                <div className="rounded-[20px] bg-white  md:w-[88%] overflow-hidden m-auto gap-5">

                        <button className="text-[32px] pr-4 pt-2 text-[#082F78] absolute z-50" onClick={handleCancel}>
                            &times;
                        </button>
                    {openLogin ? <LoginRegister/> : showSuccessLogin ? <SuccessLogin/> : null}

                </div>
            </div>
        </div>

    )
}


export default Header2