import React, {useEffect, useState} from 'react'
import circleIcon from './public/Ellipse 47.svg'
import dotts from './public/Frame 36.svg'
import square from './public/Rectangle 3512.svg'
import detailArrow from './public/detailArrow.svg'
import {useNavigate} from 'react-router-dom'
import Header2 from '../header/Header2'
import {useContext} from 'react'
import {StateApp} from '../context/Context'
import Modal from "react-modal";
import axios from 'axios'
import {url} from '../url'
import Footer from '../footer/Footer'
import LoginRegister from '../Login/LoginRegister';
import jalaali from 'jalaali-js';
import {GiCarWheel} from "react-icons/gi";
import carMapic from '../TripPage/public/car-service-svgrepo-com.svg'
import SuccessLogin from '../Modal/Success Login';
import {TbLoader2} from "react-icons/tb";
// import {BsFilterRight} from "react-icons/bs";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import {IoFilterSharp} from "react-icons/io5";



const PassengersTrips = () => {

    const [dropdown, setDropdown] = useState(false);

    // value for pagination scroll
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalDocs, setTotalDocs] = useState();
    const [totalPage, setTotalPage] = useState();
    const [loading, setLoading] = useState(false);
    const [moreData, setMoreData] = useState(true);

    const [allTrips, setAllTrips] = useState([]);


    const {openLogin, showSuccessLogin} = useContext(StateApp);
    const [apiError, setApiError] = useState(false);
    const [reloadAnimation, setReloadAnimation] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [apiRecall, setApiRecall] = useState(false);
    const [unavailableToken, setUnavailableToken] = useState(false);
    const [filterDropDown, setFilterDropDown] = useState(false);
    const [travelType, setTravelType] = useState("");


    const [status, setStatus] = useState("");

    let navigate = useNavigate(null);

    const tryAgain = () => {
        window.location.reload()
    }

    useEffect(() => {
        // if (moreData && !loading) {
        setLoading(true)
        axios.get(`${url}/travel/v1/get-all-travels-passenger`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                status: travelType,
                limit: limit,
                page: page
            }
        }).then(res => {
            setTotalDocs(res.data.data.total_count)
            setTotalPage(res.data.data.total_pages)
            setUnavailableToken(false);
            setApiError(false)
            setLoading(false)
            setAllTrips(res.data.data.docs);

        }).catch(error => {
            if (error?.response?.status === 404) {
                setAllTrips([])
            }
            if (error?.response?.status === 401)
                setUnavailableToken(true)
            if (error?.message === "Network Error")
                setApiError(true)
            setMoreData(false);
            setLoading(false)
        })
        // }


    }, [showSuccessLogin, travelType, limit, page]);

    // useEffect(() => {
    //     fetchItems()
    // }, [page, travelType]);


    // const fetchItems = async () => {
    //     setLoading(true);
    //     setUnavailableToken(false);
    //     try {
    //         const res = await axios.get(`${url}/travel/v1/get-all-travels-passenger`, {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    //             },
    //             params: {
    //                 status: travelType,
    //                 limit: limit,
    //                 page: page
    //             }
    //         });
    //         console.log(res.data.data)
    //         setAllTrips((prevItems) => [...prevItems, ...res.data.data.docs]);
    //
    //
    //         if (res.data.data.docs.items.length === 0 || res.data.data.docs.items.length < 0) {
    //             setMoreData(false);
    //         }
    //     } catch (error) {
    //         if (error?.response?.status === 404) {
    //             setAllTrips([])
    //         }
    //         if (error?.response?.status === 401)
    //             setUnavailableToken(true)
    //         if (error?.message === "Network Error")
    //             setApiError(true)
    //         setMoreData(false);
    //         setLoading(false)
    //     }
    //     setLoading(false);
    // };

    useEffect(() => {
        setPage(1)
    }, [travelType]);

    const dateConvert = (item) => {
        const persianDate = jalaali.toJalaali(new Date(item.travel_date.slice(0, 10)).getFullYear(), new Date(item.travel_date.slice(0, 10)).getMonth() + 1, new Date(item.travel_date.slice(0, 10)).getDate())
        return `${persianDate.jy}/${persianDate.jm}/${persianDate.jd}`
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            borderRadius: '10px',
            outline: 'none',
            border: 'none'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
    };

    //  const handleScroll = () => {
    //     const scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    //     const scrolled = window.scrollY;
    //
    //    if (scrolled >= scrollableHeight) {
    //        setPage(page + 1);
    //     }
    // };
    //
    //
    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () =>
    //         window.removeEventListener('scroll', handleScroll);
    // }, []);


    return (
        <div className='h-full flex flex-col min-h-[100vh] justify-between'>
            <div className='md:hidden '>
                <Header2/>
            </div>
            {
                <div className=''>
                    {
                        apiError ? (
                            <div className='flex items-center justify-center py-10 px-5'>
                                <div className='flex items-center gap-5 w-full flex-col'>
                                    <div className='errorSign bg-red-600 flex items-center justify-center'>
                                        <div
                                            className='errorSign2 bg-red-100 text-red-600 text-[84px] flex items-center justify-center'>!
                                        </div>
                                    </div>
                                    <p className='text-red-600 text-center'>خطا در برقراری ارتباط! <br
                                        className='md:block hidden'/> لطفا اینترنت خود را بررسی کنید...</p>
                                    <div className={` ${reloadAnimation ? "flex items-center gap-x-2" : "invisible"}`}>
                                        <p>منتظر بمانید...</p>
                                        <TbLoader2 className={`${reloadAnimation && "animate-spin text-[25px]"}`}/>
                                    </div>
                                    <button
                                        className=' text-[18px] border-2 border-darkBlue text-darkBlue hover:bg-darkBlue hover:text-white rounded-lg py-2 px-4 mt-5'
                                        onClick={tryAgain}>تلاش مجدد
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className={`flex flex-col justify-between p-5 h-full`}>
                                <div className='flex flex-col gap-y-2'>
                                    <h2 className='text-[24px]'>سفر های ثبت شده</h2>
                                </div>

                                <div
                                    className='flex flex-col gap-y-3 justify-center items-center py-2 md:my-auto border-t-2 border-darkBlue mt-5'>

                                    <div
                                        className="flex md:flex-col min-h-9 justify-start gap-3 md:justify-center  w-full my-3 mr-10 md:mr-0">
                                        <div className="flex md:justify-center items-center hover:cursor-pointer"
                                             onClick={() => setDropdown(!dropdown)}>
                                            <span>فیلتر ها</span>
                                            <IoFilterSharp className={`text-[20px] ${dropdown ? `-rotate-90` : `rotate-90`}`}/>
                                        </div>
                                        <div className={`${dropdown ? `flex` : `hidden`} flex md:flex-col gap-x-3 `}>

                                            <button
                                                className={`border-2 md:mt-4 rounded-md py-1 border-blueBorder px-2 hover:bg-blueBorder hover:text-white ${travelType === "" && `bg-blueBorder text-white`} `}
                                                onClick={() => setTravelType("")}
                                                disabled={loading}>
                                                همه سفر ها
                                            </button>
                                            <button
                                                className={`border-2 md:mt-4 rounded-md py-1 border-blueBorder px-2 hover:bg-blueBorder hover:text-white ${travelType === "completed" && `bg-blueBorder text-white`} `}
                                                onClick={() => setTravelType("completed")}
                                                disabled={loading}>
                                                سفر های انجام شده
                                            </button>
                                            <button
                                                className={`border-2 md:mt-4 rounded-md py-1 border-blueBorder px-2 hover:bg-blueBorder hover:text-white ${travelType === "active" && `bg-blueBorder text-white`} `}
                                                onClick={() => setTravelType("active")}
                                                disabled={loading}>
                                                سفر های فعال
                                            </button>

                                            <button
                                                className={`border-2 md:mt-4 rounded-md py-1 border-blueBorder px-2 hover:bg-blueBorder hover:text-white ${travelType === "payment_completed" && `bg-blueBorder text-white`} `}
                                                onClick={() => setTravelType("payment_completed")}
                                                disabled={loading}>
                                                سفر های پرداخت شده
                                            </button>
                                            <button
                                                className={`border-2 md:mt-4 rounded-md py-1 border-blueBorder px-2 hover:bg-blueBorder hover:text-white ${travelType === "payment_required" && `bg-blueBorder text-white`} `}
                                                onClick={() => setTravelType("payment_required")}
                                                disabled={loading}>
                                                سفر های پرداخت نشده
                                            </button>
                                            <button
                                                className={`border-2 md:mt-4 rounded-md py-1 border-blueBorder px-2 hover:bg-blueBorder hover:text-white ${travelType === "created" && `bg-blueBorder text-white`} `}
                                                onClick={() => setTravelType("created")}
                                                disabled={loading}>
                                                سفر های ایجاد شده
                                            </button>
                                            <button
                                                className={`border-2 md:mt-4 rounded-md py-1 border-blueBorder px-2 hover:bg-blueBorder hover:text-white ${travelType === "canceled" && `bg-blueBorder text-white`} `}
                                                onClick={() => setTravelType("canceled")}
                                                disabled={loading}>
                                                سفر های لغو شده
                                            </button>
                                        </div>

                                    </div>


                                    <>
                                        {!loading ?
                                            allTrips?.length > 0 ? (
                                                    <>
                                                        {
                                                            allTrips.map((item, index) => (

                                                                <div key={index}
                                                                     className='w-[90%] flex flex-col justify-center items-center border-2 border-blueBorder py-5 rounded-xl'>
                                                                    <div
                                                                        className='flex flex-col border-b-2 border-slate-200 pb-3 w-[96%]'>
                                                                        <div
                                                                            className='flex items-center justify-between w-full'>
                                                                            <div className='flex items-center gap-x-2'>
                                                                                <div
                                                                                    className='w-[10px] h-[10px] border-2 border-slate-300 rounded-full'></div>
                                                                                {item?.origin_city?.name}
                                                                            </div>
                                                                            <p className='text-greyFont'>{dateConvert(item)}</p>
                                                                        </div>

                                                                        <div className='mr-1'>
                                                                            <img src={dotts}/>
                                                                        </div>

                                                                        <div className='flex items-center gap-x-2'>
                                                                            <div
                                                                                className='border-[2px] border-slate-300 rounded-sm w-[10px] h-[10px]'></div>
                                                                            <div
                                                                                className='w-full flex items-center justify-between'>
                                                                                {item?.destination_city?.name}
                                                                                <p
                                                                                    className={`text-white rounded-md px-2 py-[1px]
                                                                                        ${item.status === "payment_completed" ?
                                                                                        `bg-blue-500`
                                                                                        :
                                                                                        ((item.status === "created") || (item.status === "payment_required")) ?
                                                                                            `bg-yellow-500`
                                                                                            :
                                                                                            item.status === "canceled" ?
                                                                                                `bg-red-600`
                                                                                                :
                                                                                                item.status === "completed" ?
                                                                                                    `bg-green-500`
                                                                                                    :
                                                                                                    item.status === "active" ?
                                                                                                        `bg-cyan-500`
                                                                                                        :
                                                                                                        null
                                                                                    }`}

                                                                                >

                                                                                    {item?.status === "payment_completed" ?
                                                                                        "پرداخت شده"
                                                                                        :
                                                                                        ((item.status === "created") || (item.status === "payment_required")) ?
                                                                                            "پرداخت نشده"
                                                                                            :
                                                                                            item.status === "canceled" ?
                                                                                                "لغو شده"
                                                                                                :
                                                                                                item.status === "completed" ?
                                                                                                    "انجام شده"
                                                                                                    :
                                                                                                    item.status === "active" ?
                                                                                                        "فعال"
                                                                                                        :
                                                                                                        null
                                                                                    }

                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className='mt-3 flex justify-between w-[96%]'>
                                                                        <div className='flex gap-x-1'>
                                                                            {item?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            <p className='font-bold'>تومان</p>
                                                                        </div>
                                                                        <div
                                                                            onClick={() => navigate(`/passengersTrips/${item._id}`)}
                                                                            className='flex cursor-pointer gap-x-2'>
                                                                            <p className='text-darkPink'>جزئیات</p>
                                                                            <img width={10} src={detailArrow}/>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            ))

                                                        }
                                                        {(limit < totalDocs) &&
                                                            <div className="flex justify-center items-center gap-2 mt-4">

                                                                <button
                                                                    className={`p-1 border-2 border-darkBlue rounded-md text-darkBlue ${page <= 1 && `text-gray-400 border-gray-400 cursor-not-allowed`} `}
                                                                    onClick={() => setPage(page - 1)}
                                                                    disabled={page <= 1}
                                                                >
                                                                    <FaArrowRight/></button>
                                                                <p className="font-black">صفحه {page} از {totalPage}</p>
                                                                <button
                                                                    className={`p-1 border-2 border-darkBlue rounded-md text-darkBlue ${(page >= totalPage) && `text-gray-400 border-gray-400 cursor-not-allowed`}`}
                                                                    onClick={() => setPage(page + 1)}
                                                                    disabled={page >= totalPage}
                                                                ><FaArrowLeft/>
                                                                </button>

                                                            </div>

                                                        }
                                                    </>
                                                ) :
                                                (
                                                    <div
                                                        className={`w-full h-full flex flex-col items-center justify-between text-[30px] py-[50px]`}>
                                                        <div
                                                            className='flex gap-x-5 items-center md:flex-col md:mx-5'>
                                                            <img src={carMapic}/>
                                                            <div className='flex flex-col md:mt-10'>
                                                                <p>{unavailableToken ? "شما وارد حساب کاربری نشده اید    !" : "شما سفر ثبت شده ای ندارید !"}</p>
                                                                <div
                                                                    className={` ${reloadAnimation ? "flex items-center md:justify-center text-slate-700  gap-x-2 mt-5" : "hidden"}`}>
                                                                    <TbLoader2
                                                                        className={`${reloadAnimation && "animate-spin text-[25px]"}`}/>
                                                                    <p className='text-[20px]'>لطفا منتظر
                                                                        بمانید...</p>
                                                                </div>
                                                                <div
                                                                    className='flex items-center gap-x-2 md:justify-center md:flex-col md:items-stretch'>
                                                                    <button
                                                                        className='bg-darkBlue w-full text-[18px] border-2 border-darkBlue rounded-lg py-2 px-4 text-white mt-5'
                                                                        onClick={() => unavailableToken ? setIsModalOpen(true) : tryAgain()}>{unavailableToken ? "ثبت نام" : "تلاش مجدد"}</button>
                                                                    <button
                                                                        className='border-2 w-full border-darkBlue text-[18px] rounded-lg py-2 px-4 text-darkBlue mt-5 md:mt-2 text-nowrap'
                                                                        onClick={() => navigate("/")}>{unavailableToken ? "بازگشت به صفحه اصلی" : "ثبت سفر"}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            :
                                            <div className=''><TbLoader2
                                                className="m-auto animate-spin text-[50px] flex h-48"/></div>
                                        }
                                    </>

                                    {/*{*/}
                                    {/*    loading &&*/}
                                    {/*    <div className=''><TbLoader2*/}
                                    {/*        className="m-auto animate-spin text-[50px] flex h-48"/></div>*/}
                                    {/*}*/}
                                    {/*{*/}
                                    {/*    !moreData &&*/}
                                    {/*    <div>*/}
                                    {/*        No more data*/}
                                    {/*    </div>*/}
                                    {/*}*/}


                                </div>
                            </div>
                        )
                    }
                </div>
            }
            <div>
                <Footer/>
            </div>


            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCancel}
                style={customStyles}
                className="modalMobile"
            >

                <div className=''>
                    <button className="custom-modal-close" onClick={handleCancel}>
                        &times;
                    </button>
                    {openLogin ? <LoginRegister/> : showSuccessLogin ? <SuccessLogin/> : <LoginRegister/>}
                </div>
            </Modal>
        </div>
    )
}

export default PassengersTrips
