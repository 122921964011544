import React, {useState, useEffect} from 'react'
import Header2 from '../header/Header2'
import Header from '../header/Header'
import {url} from '../url'
import axios from 'axios'
import Modal from "react-modal";
import LoginRegister from '../Login/LoginRegister';
import carMapic from '../../src/TripPage/public/car-service-svgrepo-com.svg'
import SuccessLogin from '../Modal/Success Login';
import {TbLoader2} from "react-icons/tb";
import {BsFilterRight} from "react-icons/bs";
import {useNavigate} from 'react-router-dom'
import {useContext} from 'react'
import {StateApp} from '../context/Context'
import Footer from '../footer/Footer'


const Transactions = () => {

    const convertToPersianNumbers = (str) => {
        const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        return str.replace(/\d/g, (digit) => persianDigits[digit]);
    };


    const [transactionsList, setTransactionsList] = useState([])
    const [showDescModal, setShowDescModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [unavailableToken, setUnavailableToken] = useState(false);
    const [reloadAnimation, setReloadAnimation] = useState(false);
    const [loading, setLoading] = useState(false);

    const {openLogin, showSuccessLogin} = useContext(StateApp);

    const [apiRecall, setApiRecall] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${url}/transaction/v1/get-passenger-transactions`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }

        })
            .then(res => {
                console.log('transaction list : ', res.data.data.docs);
                setTransactionsList(res.data.data.docs);
                setUnavailableToken(false)
                setLoading(false)

            }).catch(err => {
            if (err?.response?.status === 401)
                setUnavailableToken(true)
            console.log("e");
            setLoading(false)


        })

    }, [apiRecall, showSuccessLogin])

    const [origin, setOrigin] = useState("")
    const [destination, setDestination] = useState("")
    const [address, setAddress] = useState("")


    const [price, setPrice] = useState("")
    const [price_paid, setPrice_paid] = useState("")
    const [travelDate, setTravelDate] = useState("")
    const [travelHour, setTravelHour] = useState("")
    const [description, setDescription] = useState("")
    const [passangerName, setPassangerName] = useState("")
    const [passangerFatherName, setPassangerFatherName] = useState("")
    const [passangerPhoneNumber, setPassangerPhoneNumber] = useState("")


    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            borderRadius: '10px',
            outline: 'none',
            border: 'none'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
    };

    let navigate = useNavigate(null);
    const tryAgain = () => {
        setTimeout(() => {
            setApiRecall(prev => !prev)
            setReloadAnimation(false);
        }, 3000);
        setReloadAnimation(true);
    }


    const HandelDesc = (value) => {
        setShowDescModal(true)

        setShowDescModal(true)
        setOrigin(value.travel.origin_city.name)
        setDestination(value.travel.destination_city.name)
        setAddress(value.travel.address)
        setDescription(value.description)
        setPrice(value.travel.price)
        setPrice_paid(value.travel.price_paid)
        setTravelDate(Intl.DateTimeFormat('fa-IR').format(Date.parse(value.travel.travel_date)))
        setTravelHour(value.travel.travel_hour_time)
        setPassangerName(value.travel.passenger_snapshot.full_name)
        setPassangerFatherName(value.travel.passenger_snapshot.father_name)
        setPassangerPhoneNumber(value.travel.passenger_snapshot.mobile)
    }


    let d = new Date();
    console.log(d.toLocaleDateString("fa-IR"));

    return (

        <div className="flex flex-col min-h-[100vh]">
            <Header2></Header2>
            <div style={{flexGrow: 1}}>
                <div className='TransactionsList-header flex  w-11/12 h-14 bg-[#FFB800] rounded-lg  mx-auto my-5'>
                    <p className='w-1/6 md:w-1/4   font-bold text-white  justify-center items-center flex '>مبدا</p>
                    <p className='w-1/6  md:w-1/4  font-bold  text-white  justify-center items-center flex'>مقصد</p>
                    <p className='w-1/6  md:w-1/4  font-bold  text-white  justify-center items-center flex'>تاریخ
                        سفر</p>
                    <p className='w-1/6  md:hidden  font-bold  text-white  justify-center items-center flex'>قیمت کل</p>
                    <p className='w-1/6   md:hidden  font-bold  text-white  items-center flex '>قیمت پرداخت شده</p>
                    <p className='w-1/6  md:w-1/4  justify-center   font-bold  text-white  items-center flex '>عملیات</p>
                </div>
                <div className='TransactionsList  justify-around w-11/12 mx-auto my-5  rounded-lg '>


                    {transactionsList.length ? transactionsList.map((transaction) => {
                        return (
                            <div
                                className='TransactionsList-header flex justify-around w-full h-14 mb-2  mx-auto bg-blue-100'>
                                <div
                                    className='w-1/6  justify-center items-center flex rounded-r-lg'>{transaction && transaction.travel && transaction.travel.origin_city ? transaction.travel.origin_city.name : "-"}</div>
                                <div
                                    className='w-1/6  justify-center items-center flex'>{transaction && transaction.travel && transaction.travel.destination_city ? transaction.travel.destination_city.name : "-"}</div>
                                <div
                                    className='w-1/6  justify-center items-center flex'>{transaction && transaction.travel && transaction.travel.travel_date ? Intl.DateTimeFormat('fa-IR').format(Date.parse(transaction.travel.travel_date)) : "-"}</div>
                                <div
                                    className='w-1/6 md:hidden   justify-center items-center flex'>{transaction && transaction.travel && transaction.travel.price ? transaction.travel.price.toLocaleString("fa") : "-"} تومان
                                </div>
                                <div
                                    className='w-1/6  md:hidden  items-center flex '>{transaction && transaction.travel && transaction.travel.price_paid ? transaction.travel.price_paid.toLocaleString("fa") : "-"} تومان
                                </div>
                                <div className='w-1/6  text-blue-700 justify-center items-center flex rounded-l-lg'>
                                    <button onClick={() => {
                                        HandelDesc(transaction)
                                    }}>نمایش
                                    </button>
                                </div>
                            </div>


                        )
                    }) : loading ? <TbLoader2 className="m-auto animate-spin text-[50px] h-48"/>
                        :
                        <div
                            className={`w-full h-full flex flex-col items-center justify-between text-[30px] py-[50px]`}>
                            <div className='flex gap-x-5 items-center md:flex-col md:mx-5'>
                                <div className='flex flex-col md:mt-10'>
                                    <p>{unavailableToken ? "شما وارد حساب کاربری نشده اید    !" : "شما تراکنش ثبت شده ای ندارید !"}</p>

                                    <div
                                        className={` ${reloadAnimation ? "flex items-center md:justify-center text-slate-700  gap-x-2 mt-5" : "hidden"}`}>
                                        <TbLoader2 className={`${reloadAnimation && "animate-spin text-[25px]"}`}/>
                                        <p className='text-[20px]'>لطفا منتظر بمانید...</p>
                                    </div>
                                    <div
                                        className='flex items-center gap-x-2 md:justify-center md:flex-col md:items-stretch'>
                                        <button
                                            className={`bg-darkBlue w-full text-[18px] border-2 border-darkBlue rounded-lg py-2 px-4 text-white mt-5  ${!unavailableToken ? "hidden" : ""} `}
                                            onClick={() => unavailableToken ? setIsModalOpen(true) : tryAgain()}>{unavailableToken ? "ثبت نام" : null}</button>
                                        <button
                                            className={`border-2 w-full border-darkBlue text-[18px] rounded-lg py-2 px-4 text-darkBlue mt-5 md:mt-2 text-nowrap  ${!unavailableToken ? "hidden" : ""} `}
                                            onClick={() => navigate("/")}>{unavailableToken ? "بازگشت به صفحه اصلی" : null}</button>
                                    </div>
                                </div>
                            </div>

                        </div>}

                </div>
            </div>

            <div>
                <Footer/>
            </div>

            <Modal
                isOpen={showDescModal}
                style={{
                    content: {
                        maxWidth: "600px",
                        margin: "auto",
                        padding: "20px",

                    },
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                }}
            >
                <div className=''>
                    <h2 className=' mb-2 mr-4 font-bold'>اطلاعات تراکنش</h2>
                    <hr/>
                    <div className="modal-body gap-3 mb-5">

                        <p className='border border-solid  my-4 rounded-lg p-2'>مبدا : {origin}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>مقصد : {destination}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>توضیحات : {description}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>آدرس : {address}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>هزینه کل : {price.toLocaleString("fa")}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>هزینه پرداخت شده : {price_paid.toLocaleString("fa")}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>تاریخ سفر : {travelDate}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>ساعت سفر : {convertToPersianNumbers(travelHour)}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>نام مسافر : {passangerName}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>نام پدر مسافر
                            : {passangerFatherName}</p>
                        <p className='border border-solid  my-4 rounded-lg p-2'>شماره موبایل مسافر
                            : ۰{convertToPersianNumbers(passangerPhoneNumber)}</p>

                    </div>
                    <button
                        className='bg-blue-200 hover:bg-blue-300 transition ease delay-50 w-32  rounded-lg border border-solid  md:w-full rounded-lg p-2 '
                        onClick={() => setShowDescModal(false)}>
                        بازگشت
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCancel}
                style={customStyles}
                className="modalMobile"
            >

                <div className=''>
                    <button className="custom-modal-close" onClick={handleCancel}>
                        &times;
                    </button>
                    {openLogin ? <LoginRegister/> : showSuccessLogin ? <SuccessLogin/> : <LoginRegister/> }
                </div>
            </Modal>

        </div>
    )
}

export default Transactions