import React from "react"
import banner2 from "./banners/Rectangle 19.png";
import banner1 from "./banners/Rectangle 18.png";
import Icon1 from "./bodyIcons/svgexport-10 1.png";
import Icon2 from "./bodyIcons/Mask group.png";
import Icon3 from "./bodyIcons/Mask.png";
import Icon4 from "./bodyIcons/Ticket.png";
import Taxi1 from "./TaxiPicture/Rectangle 23.png";
import Taxi2 from "./TaxiPicture/Rectangle 24.png";
import TaxiArt from "./TaxiPicture/taxiArt.png";
import { FaMobileScreenButton } from "react-icons/fa6";
import QrPicture from "./TaxiPicture/Frame 1.png";
import qrCode from '../public/bodyIcons/marankooQRCode.svg'
import { LuScanFace } from "react-icons/lu";

import taxiicon1 from '../public/taxiCarIcon.png'
import taxiicon2 from '../public/taxiLocatino-Icon.png'

import { FaTaxi, FaUser } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const Details = () => {
    const navigate = useNavigate(null)

    return (<div className="md:pb-5">
        <div
            className="md:grid-cols-1 grid grid-cols-2 justify-center mt-[20px] gap-y-[20px] gap-x-[60px] tablet10:p-10 tablet10:flex tablet10:flex-col tablet10:justify-center tablet10:items-center">
            <div className=" col-span-1 flex items-center justify-end md:justify-center">
                <img className="md:w-[95%] md:h-auto w-[622px] h-[270px]" src={banner2} alt="Banner 1" />
            </div>
            <div className=" col-span-1 flex items-center justify-start md:justify-center">
                <img className=" md:w-[95%] md:h-auto w-[622px] h-[270px]" src={banner1} alt="Banner 2" />
            </div>
        </div>

        <div className="w-[80%] mx-auto my-10 mt-20 flex flex-col gap-y-8 lg:py-16 lg:pb-8 md:mb-0">
            <div className="flex items-center gap-x-10 w-full
                lg:flex-col lg:bg-slate-200 rounded-[20px] lg:max-w-[500px] lg:w-full lg:mx-auto lg:pb-8 md:mb-0">
                <img src={taxiicon1} className="w-[200px] hover:translate-x-6 lg:hover:translate-x-0 duration-200" />
                <p className="border-r-amber-400 border-r-2 pr-2 w-[60%] text-slate-800
                lg:border-none lg:max-w-[450px] lg:w-full lg:text-wrap lg:break-words lg:px-5">
                    <span className="text-black text-[24px]">سفر مطمئن و سریع</span><br />
                    سرویس اجاره تاکسی بین‌شهری ما، سفری مطمئن و آسوده را بدون محدودیت زمان حرکت و دغدغه‌ی ایستگاه‌ها و ترمینال‌ها برایتان فراهم می‌کند. خدمات ما جایگزینی مناسب برای اتوبوس، قطار و حتی هواپیما (در شرایط تکمیل ظرفیت) است.
                </p>
            </div>

            <div className="flex items-center justify-end gap-x-10 w-full
                lg:flex-col lg:bg-slate-200 rounded-[20px] lg:max-w-[500px] lg:w-full lg:mx-auto lg:pb-8">
                <p className="border-l-amber-400 border-l-2 pl-2 w-[60%] text-slate-800
                lg:border-none lg:max-w-[450px] lg:w-full lg:order-2 lg:text-wrap lg:break-words lg:px-5">
                    <span className="text-black text-[24px]">سفری آسوده و راحت</span><br />
                    سرویس تاکسی بین‌شهری ما پاسخی مناسب برای مسافران هوشمندی است که می‌خواهند از محدودیت‌ها و پیچیدگی‌های سیستم حمل و نقل عمومی عبور کنند و سفری سریع، اختصاصی و به‌مراتب راحت‌تر را تجربه کنند. با خدمات تاکسی ما، نه‌تنها زمان حرکت را طبق برنامه‌ی شخصی خودتان تعیین می‌کنید، بلکه از مبدأ تا مقصد بدون توقف و با آسودگی کامل همراه خواهید بود.
                </p>
                <img src={taxiicon2} className="w-[200px] hover:animate-bounce
                lg:order-1 lg:py-5 lg:hover:animate-none" />
            </div>
        </div>

        <div className="hiddenAnimeLeftToRight flex justify-center items-center mb-[120px] md:mb-[60px] p-10">
            <div className="grid md:grid-cols-1 grid-cols-4 mt-[60px] w-[1320px] gap-10">
                <div className=" hiddenAnimeLeftToRight2 grid md:hidden grid-cols-1 ">
                    <img src={TaxiArt} />
                </div>

                <div className="col-span-2 flex flex-col md:mr-0 mr-[60px]" style={{ direction: "rtl" }}>
                    <p style={{ fontSize: "32px", fontWeight: "700", textAlign: "right" }}>آسان تر با اپلیکیشن</p>
                    <div className="flex gap-6 mt-5">
                        <div className="flex flex-col gap-y-2">
                            <a href="https://marankoo.ir/wp-content/uploads/android/maranko-passenger.apk" className="flex items-center gap-x-2 bg-blue-400 p-4 rounded-[10px]"><FaUsers className="text-[24px]" />دریافت مستقیم اپلیکیشن مسافر <LuDownload className="text-[24px]" /></a>
                            <a href="https://marankoo.ir/wp-content/uploads/android/maranko-driver.apk" className="flex items-center gap-x-2 bg-yellow-300 p-4 rounded-[10px]"><FaTaxi className="text-[24px]" />دریافت مستقیم اپلیکیشن راننده <LuDownload className="text-[24px]" /></a>
                        </div>
                    </div>
                </div>

                <div className="col-span-1 justify-self-end md:justify-self-center md:hidden">
                    <div className="md:flex md:justify-center md:flex-col flex flex-col items-center md:mx-auto">
                        <img src={qrCode} className="w-[200px] h-[200px]" />
                        <p className="text-center">برای دانلود اپلیکیشن مسافر اسکن کنید</p>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Details;