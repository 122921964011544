import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./Fonts/IRANSansWeb.woff";
import States from "./context/Context";
import { Provider } from "react-redux";
import configureStore from "../src/Store/index";
ReactDOM.render(
        <BrowserRouter>
            <States>
                <Provider store={configureStore}>
                    <App />
                </Provider>
            </States>
        </BrowserRouter>,
    document.getElementById('root')
);

// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//         .register(`http://localhost:3000/firebase-messaging-sw.js`)
//         .then((registration) => {
//             console.log(
//                 "Service Worker registration successful with scope: ",
//                 registration.scope  
//             );
//         })
//         .catch((err) => {
//             console.error("Service Worker registration failed: ", err);
//         });
// }
reportWebVitals();
