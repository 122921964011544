import React, { useContext, useState } from "react";
import Image from "./public/picture.jpg"
import Verification from "../Login/VerificationCode";
import { StateApp } from "../context/Context";
import Cookies from 'js-cookie';
import axios from "axios";
import { Api } from "../api/api";
import { message, notification } from "antd";
import Loading from "../Loading/Loading";
import { url } from "../url";

const Login = () => {
    const { handleVerification, setHandleVerification, loading, setLoading } = useContext(StateApp);
    const [phoneNumber, setPhoneNumber] = useState("");

    const convertToEnglishNumbers = (input) => {
        const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        const englishNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        return input.replace(/[۰-۹]/g, (match) => englishNumbers[persianNumbers.indexOf(match)]);
    };

    const handleInputChange = (e) => {
        setPhoneNumber(convertToEnglishNumbers(e.target.value));
    };

    const handleLogin = (e) => {
        setLoading(true);
        axios.post(`${url}/passenger/v1/send-otp`, {
            mobile: phoneNumber
        })
            .then((response) => {
                if (response.status === 200) {
                    setHandleVerification(true);
                    setLoading(false);
                    message.success(response.data.message);
                }
            })
            .catch((error) => {
                if (error.response?.status !== 200 && error.response?.status !== 409) {
                    notification.error({
                        message: error.response.data.message
                    })
                    setLoading(false);
                }
                if(error.response.status === 409){
                    setHandleVerification(true)
                    setLoading(false)
                }
                console.log(error);

            });
        e.preventDefault();
        Cookies.set('phoneNumber', phoneNumber);
    };

    return (
        <>
            {handleVerification ? (
                <div className="w-full">
                    <Verification phoneNumber={phoneNumber} />
                </div>
            ) : (
                <div className="w-[100%] gap-10 flex flex-col justify-center items-center">
                    <p style={{ fontFamily: "IRANSans" }} className="text-[22px] font-[600] text-[#100E34] mt-10">
                        ورود به حساب کاربری
                    </p>
                    <form
                        className="md:w-full md:justify-center md:items-center w-full flex flex-col justify-center"
                        onSubmit={handleLogin}
                    >
                        <div
                            className="relative flex flex-col items-center w-full h-full md:flex md:justify-center md:items-center md:flex-col">
                            <div className="form w-[100%] md:flex md:flex-col md:justify-center md:items-center">
                                <div className="w-[100%] h-[54px] flex justify-center relative">
                                    <div className="w-full h-full flex">
                                        <span className="fixed-code">98+</span>

                                        <input
                                            type="text"
                                            id="phoneNumber"
                                            className="form__input"
                                            autoComplete="off"
                                            placeholder=" "
                                            value={convertToEnglishNumbers(phoneNumber)}
                                            onChange={handleInputChange}
                                            maxLength={10}
                                            style={{ paddingLeft: '3rem' }}
                                            autoFocus={true}
                                        />
                                        <label htmlFor="phoneNumber" className="form__label">
                                            شماره موبایل
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="md:mb-10 w-[80%] md:w-full h-[80px] flex justify-center mt-4">
                                <button
                                    type="submit"
                                    className="h-full w-full flex justify-center flex-col items-center md:flex md:flex-col md:justify-center md:items-center bg-[#4F48EC] md:w-[80%] md:h-[43px] md:rounded-[10px] rounded-[10px]"
                                >
                                    {loading ? (
                                        <Loading />
                                    ) : (
                                        <p style={{ fontFamily: "IRANSans" }}
                                            className="text-white text-[22px] md:text-[18px]">ورود</p>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default Login;
