import React, { useContext, useEffect, useState, useRef } from 'react';
import { StateApp } from "../context/Context";
import Login from "../Login/Login";
import Cookies from 'js-cookie';
import "../Login/Login.css";
import axios from "axios";
import Loading from "../Loading/Loading";
import { message, notification } from "antd";
import SuccessLogin from "../Modal/Success Login";
import { url } from '../url';
import { messaging, requestForToken, onMessageListener } from '../firebase';



const Verification = ({ phoneNumber }) => {

    // timer
    const [showTimer, setShowTimer] = useState(true)
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    const {
        handleVerification,
        setHandleVerification,
        loading,
        setLoading,
        setOpenlogin,
        setshowSuccsessLogin,
        completedProfile,
        setcompletedProfile
    } = useContext(StateApp);
    const [showPhone, setShowphone] = useState("");
    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);




    const convertToEnglishNumbers = (input) => {
        const persianNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        const englishNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        return input.replace(/[۰-۹]/g, (match) => englishNumbers[persianNumbers.indexOf(match)]);
    };

    const EditPhone = () => {
        setHandleVerification(!handleVerification);
    };

    useEffect(() => {
        const phoneNumber = Cookies.get("phoneNumber");
        setShowphone(phoneNumber);
    }, []);

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value.length === 1 && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };


    function resendCode () {

        setSeconds(0)
        setMinutes(2)

        axios.post(`${url}/passenger/v1/send-otp`, {
            mobile: phoneNumber
        }).then((response) => {
            if (response.status === 200) {
                setHandleVerification(true);
                setShowTimer(true)
                setLoading(false);
                message.success(response.data.message);
            }
        }).catch((error) => {
            if (error) {
                notification.error({
                    message: error?.response?.data?.message
                })
                setLoading(false);
                setShowTimer(false)
            }
        });
    }

    const submit = () => {
        setLoading(true);
        const enteredOtp = convertToEnglishNumbers(otp.slice().join(''));

        axios.post(`${url}/passenger/v1/submit-otp`, {
            code: enteredOtp,
            mobile: showPhone
        })
            .then((response) => {
                if (response.status === 200) {
                    setHandleVerification(false);
                    setOpenlogin(false);
                    setLoading(false);
                    setshowSuccsessLogin(true);
                    localStorage.setItem('accessToken', response.data.data.tokens.accessToken);
                    if (response.data.data.status === "profile_not_completed") {
                        setcompletedProfile(false);
                    } else {
                        setcompletedProfile(true);
                    }
                    requestForToken()
                }
            })
            .catch((error) => {
                if (error) {
                    console.log(error);
                    
                    message.error(error.response?.data.message);
                    setLoading(false);
                } else {        
                    setLoading(false);
                }
            });
    };
    // console.log(showTimer);





    useEffect(() => {
        if (handleVerification) {
            const interval = setTimeout(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                    console.log("seconds - 1")
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearTimeout(interval);
                        console.log("ending")
                        setShowTimer(false)
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                        console.log("resent seconds and minutes -1 ")
                    }
                }
            }, 1000);

            return () => {
                clearTimeout(interval);
            };
        } else {
            setSeconds(0)
            setMinutes(2)
        }
    }, [seconds]);


    return (
        <div className="">
            {!handleVerification ? (
                <Login />
            ) : (
                <div className="w-full p-5" style={{ fontFamily: "IRANSans" }}>
                    <div className="flex flex-col justify-center items-center w-[100%]">
                        <p className="text-[22px] font-[500]">کد تأیید را وارد کنید</p>
                        <p className="gap-1 flex text-[#8A8D97] text-[16px] mt-4">
                            کد تایید به شماره
                            <p className="text-black text-[16px] font-[600]">{showPhone}</p>
                            ارسال شد
                        </p>
                        <div style={{ direction: "ltr" }} className="flex gap-4 mt-6 ">
                            {otp.map((digit, index) => (
                                <input
                                    ref={el => inputRefs.current[index] = el}
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={convertToEnglishNumbers(digit)}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    className={`text-[26px] md:w-[40px] md:h-[50px] w-[50px] h-[68px] text-center border border-gray-300 rounded-md focus:outline-none focus:border-[#100E34] ${digit ? 'input-filled' : ''}`}
                                    style={{ direction: 'ltr' }}
                                    pattern="[0-9]"
                                    autoFocus={index === 0}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-center items-center gap-1 mt-6">
                        <p className="text-[16px]">شماره موبایل اشتباه است ؟</p>
                        <p onClick={EditPhone} className="text-[16px] text-[#4F48EC] cursor-pointer">ویرایش</p>
                    </div>
                    <div className="flex flex-col justify-center items-center w-[100%]">

                        <div className={`flex items-center gap-x-2`}>
                            <button className={`text-[16px] text-darkBlue cursor-pointer ${showTimer && `hover:cursor-not-allowed`}`} disabled={showTimer === true} onClick={resendCode}>ارسال مجدد کد</button>
                            <div className={`${showTimer ? "block" : "hidden"}`}>
                                {(minutes === 0) && (seconds === 0) ? null :
                                    <span> {("0" + minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds)} </span>
                                }
                            </div>
                        </div>
                        <button onClick={submit} className="mt-10 bg-[#4F48EC] md:w-[90%] md:h-[40px] md:rounded-[10px] w-[80%] h-[48px] rounded-[10px]">
                            {loading ? (
                                <Loading />
                            ) : (
                                <p className="text-white text-[22px] md:text-[16px]">تایید</p>
                            )}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Verification;
