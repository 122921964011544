import React, {useContext, useEffect, useState} from "react";
import Header2 from "../header/Header2";
import paymentImg from "./public/PaymentInfo.png";
import Footer from "../footer/Footer";
import GetProfile from "../profile/GetProfile";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker, {Calendar} from "react-multi-date-picker"
import {StateApp} from "../context/Context";
import {useSelector} from "react-redux";
import axios from "axios";
import {url} from "../url";
import {RiBankCardFill} from "react-icons/ri";
import {FaWallet} from "react-icons/fa6";
import {notification} from "antd";
import {TbLoader2} from "react-icons/tb";


const Payment = () => {

    const location = useLocation()
    const travelID = localStorage.getItem("travelID");
    const navigate = useNavigate();
    // const {travelData, setTravelData} = useContext(StateApp);
    const {isLocked, setIsLocked} = useContext(StateApp);
    const [token, setToken] = useState("");
    // const { transactionPage, setTransactionPage } = useContext(StateApp);
    const [allPaymentDetails, setAllPaymentDetails] = useState([])

    const [selectedPayment, setSelectedPayment] = useState("internetPayment");

    const [loading, setLoading] = useState(true)


    const handleCheckboxChange = (id) => {
        if (selectedPayment === id) {
            setSelectedPayment(null); // Uncheck if the same is clicked again
        } else {
            setSelectedPayment(id); // Check the new selected one
        }
    };
    const tk = localStorage.getItem('accessToken');

    const cancelTrip = () => {
        axios.put(`${url}/travel/v1/cancel-travel-passenger`, {
            travel: travelID
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(res => {
            console.log('cancel trip', res);
            navigate('/')
            notification.success({
                message: res.data.message
            })
        }).catch(err => {
            console.log('cancel err : ', err);
            notification.error({
                message: "خطا در لغو سفر !"
            })
        })
    }

    useEffect(() => {
        setLoading(true)
        axios.get(`${url}/travel/v1/get-detail-of-pay/${travelID}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(res => {
            setLoading(false)
            setAllPaymentDetails(res.data.data);
        }).catch(err => {
            setLoading(true)
            if (err.response) {
                notification.error({
                    message: "سفری وجود ندارد!"
                })
            }
        })
    }, [])

    useEffect(() => {
        const storedData = localStorage.getItem('travelData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
        } else {
            navigate("/")
        }
    }, []);

    const handleClick = () => {
        axios.post(`${url}/transaction/v1/request-transaction`,
            {
                travel_id: travelID,

            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(res => {
            setToken(res.data.data.token)
            setIsLocked(true);
            // localStorage.removeItem('travelData');
        })
            .catch(err => {
            });
    };
    if (token !== "")
        window.location.href = `https://www.zarinpal.com/pg/StartPay/${token}`;

    return (
        <div className="relative">
            <div className="md:hidden mb-2">
                <Header2/>
                <GetProfile/>
            </div>

            {/* <div className="sticky top-2 right-2 rounded-lg p-5 bg-white drop-shadow-lg w-full max-w-[200px] md:hidden">
                <div className="flex items-center gap-x-2">
                    <FaWallet className="text-darkBlue" />
                    <p>اعتبار کیف پول شما:</p>
                </div>
                <p>۱۰۰.۰۰۰ تومان</p>

                <button className="text-white bg-darkBlue rounded-lg px-4 py-2 text-[14px] mt-2">افزایش اعتبار</button>
            </div> */}

            <div className="flex flex-col gap-[42px] md:gap-0">
                <div className="md:h-[70px] md:flex md:flex-col md:justify-center md:items-center">
                    <p className="text-black text-[26px] mr-[105px] mt-[42px] md:mr-[0px] md:mt-[0px]">
                        صفحه پرداخت
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center mb-24">
                    <div className="w-[87%] md:w-[95%] h-[100%] bg-white rounded-[20px] shadow-[#00000040]">
                        <div className="w-[100%] h-[100%] flex flex-col">
                            <div className="justify-center flex">
                                <img src={paymentImg} alt="پرداخت" className="w-[350px] h-[350px]"/>
                            </div>
                            {!loading ? (
                                    <div className="flex flex-col w-[90%] mx-auto gap-4">
                                        <div
                                            className="p-2 pr-5 pl-5 rounded-[20px] bg-gray-200 flex justify-between items-center w-[100%] mx-auto mb-4">
                                            <div className="flex gap-2">
                                                <p className="font-bold text-[16px]">مبدا :</p>
                                                <p className="font-bold text-[16px]">{allPaymentDetails.origin_city?.name}</p>
                                            </div>
                                            <div className="flex-grow mx-4 border-t-4 border-gray-600 border-dotted"></div>
                                            <div className="flex gap-2 items-center">
                                                <p className="font-bold text-[16px]">مقصد :</p>
                                                <p className="font-bold text-[16px]">{allPaymentDetails.destination_city?.name}</p>
                                            </div>
                                        </div>
                                        <div
                                            className="w-[100%] flex justify-between md:flex-col md:justify-center items-center gap-3 mb-5">
                                            <div
                                                className="gap-2 bg-gray-200 flex w-[200px] p-2 rounded-[20px] justify-center items-center">
                                                <p>ساعت سفر:</p>
                                                <p className="text-[16px]">{allPaymentDetails.travel_hour_time}</p>
                                            </div>
                                            <div
                                                className="text-[16px] gap-2 bg-gray-200 flex w-[180px] p-2 rounded-[20px] justify-center items-center">
                                                <p className="text-nowrap">تاریخ سفر:</p>
                                                <DatePicker
                                                    calendar={persian}
                                                    locale={persian_fa}
                                                    value={allPaymentDetails.travel_date}
                                                    readOnly
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        outline: "none",
                                                        border: "none",
                                                        width: "10px"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex justify-between flex-wrap gap-x-10 ">
                                            <div className="text-right md:border-b-2 border-gray-300 md:w-full pb-5">
                                                <div className="w-full flex flex-col gap-2">
                                                    <div className="flex justify-between gap-3">
                                                        <p>نام مسافر:</p>
                                                        <p>{allPaymentDetails.passenger_snapshot?.full_name}</p>
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <p className="ml-2"> کدملی : </p>
                                                        <p>{allPaymentDetails.passenger_snapshot?.national_code}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" text-left flex-col flex gap-2 pt-5 md:w-full">
                                                <div className="flex justify-between ">
                                                    <p>هزینه سفر:</p>
                                                    <span className="text-[#00D222]">{allPaymentDetails.price}</span>
                                                </div>
                                                <div className="flex justify-between gap-10">
                                                    <p>پرداخت هزینه اولیه:</p>
                                                    <span
                                                        className="text-[#00D222]">{allPaymentDetails.priceToPay}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full flex flex-col justify-between my-5">
                                            <div className="border-b-2 border-slate-300">
                                                <p className="text-[24px]">روش پرداخت</p>
                                            </div>

                                            <div className="flex flex-col gap-y-10 mt-5">
                                                <div className="flex gap-x-2 items-center">
                                                    <RiBankCardFill className="text-[34px] text-darkBlue"/>
                                                    <p className="text-[20px]">پرداخت اینترنتی</p>
                                                    <div className="checkbox-wrapper-31">
                                                        <input

                                                            type="checkBox"
                                                            name="paymentMethod"
                                                            checked={selectedPayment === 'internetPayment'}
                                                            id="internetPayment"
                                                            onChange={() => handleCheckboxChange('internetPayment')}
                                                        />
                                                        <svg viewBox="0 0 35.6 35.6">
                                                            <circle className="background" cx="17.8" cy="17.8"
                                                                    r="17.8"></circle>
                                                            <circle className="stroke" cx="17.8" cy="17.8"
                                                                    r="14.37"></circle>
                                                            <polyline className="check"
                                                                      points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                                                        </svg>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                <div className=''><TbLoader2
                                    className="m-auto animate-spin text-[50px] flex h-48"/></div>
                            }
                            <div
                                className="flex flex-row justify-between m-[50px] md:flex-col-reverse md:mr-0 md:ml-0 md:mb-[12px] md:items-center">
                                <button onClick={() => window.location.pathname = '/passengersTrips'}
                                        className="bg-[#EC4848] md:w-[90%] w-[437px] h-[50px] rounded-[10px] m-2">
                                    <p className="text-[22px] text-white">انصراف</p>
                                </button>
                                <button onClick={() => selectedPayment === "internetPayment" ? handleClick() : null}
                                        className="bg-[#65DF9D] md:w-[90%] w-[437px] h-[50px] rounded-[10px] m-2">
                                    <p className="text-[22px] text-white">پرداخت</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Payment;
