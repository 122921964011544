import axios from 'axios';
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {url} from '../url';
import image from './public/carGoodLokkingIcon.svg'
import passenger from './public/passenger.svg'
import Modal from "react-modal";
import {notification} from 'antd';
import {FaTrash} from "react-icons/fa";
import {GiTwoCoins} from "react-icons/gi";
import Header2 from '../header/Header2';
import mom from "moment-jalaali";
import {TbLoader2} from "react-icons/tb";


const SingleTripDetails = () => {
    const specificTripId = useParams()
    const [dataInfo, setDataInfo] = useState(null)
    const navigate = useNavigate(null)
    const [token, setToken] = useState("");
    const mom = require("moment-jalaali");
    const [cancelTripModal, setCancelTripModal] = useState(false);
    const [payTripModal, setPayTripModal] = useState(false);

    const [loading, setLoading] = useState(false);

    // console.log('specific id : ', specificTripId);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            borderRadius: '10px',
            backgroundColor: "white",
            outline: 'none',
            border: 'none'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
    };

    const cancelTrip = () => {
        axios.put(`${url}/travel/v1/cancel-travel-passenger`, {
            travel: dataInfo?._id
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(res => {
            console.log('cancel trip', res);
            setCancelTripModal(false)
            navigate("/passengersTrips")
            notification.success({
                message: res.data.message
            })
        }).catch(err => {
            console.log('cancel err : ', err);
            setCancelTripModal(false)
            notification.error({
                message: err?.response?.data?.message + "!"
            })

            if (err.message === "Network Error") {
                notification.error({
                    message: "خطا در اتصال به اینترنت !"
                })
            }
        })
    }

    useEffect(() => {
        setLoading(true)
        axios.get(`${url}/travel/v1/get-single-travel-passenger/${specificTripId.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                setDataInfo(res.data.data);
                setLoading(false)
            })
            .catch(err => {
                setLoading(true)
                console.log(err.data);
                /*
                  handle errors :
                  internet
                  api error
                */
            })
    }, [])

    const [totalPrice, setTotalPrice] = useState('')
    const [priceToPay, setPriceToPay] = useState('')
    useEffect(() => {
        axios.get(`${url}/travel/v1/get-detail-of-pay/${specificTripId.id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        })
            .then(res => {
                console.log('price res', res);
                setTotalPrice(res.data.data.price)
                setPriceToPay(res.data.data.priceToPay)
            })
            .catch(err => console.log(err))
    }, [])

    const handleClick = () => {
        axios.post(`${url}/transaction/v1/request-transaction`,
            {
                travel_id: dataInfo?._id
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(res => {
            setToken(res.data.data.token)
            localStorage.setItem("travelID", dataInfo?._id)
        })
            .catch(err => {
                /*
                 handle errors :
                 internet
                 api error
               */
            });
    };

    const handleCancel = () => {
        setCancelTripModal(false)
    }

    // --- go to zarinpal pay page ---

    if (token !== "")
        window.location.href = `https://www.zarinpal.com/pg/StartPay/${token}`;

    return (
        <div className='flex flex-col justify-between h-full w-full gap-10'>
            <div className={`md:hidden z-50`}>
                <Header2/>
            </div>
            <div className='w-full h-full flex items-center justify-center relative'>

                {loading ?
                    <div className=''><TbLoader2 className="m-auto animate-spin text-[50px] flex h-48"/></div>
                    :
                    <div className='w-[80%] p-10 max-w-[1600px] text-[20px] bg-white rounded-xl drop-shadow-lg
           md:w-full md:!max-w-[100%] md:h-full md:drop-shadow-none'>
                        {/* اطلاعات سفر  */}

                        <div className='w-full flex flex-col border-b-2 border-slate-300 pb-5'>
                            <div className='flex mx-auto flex-col pb-5'>
                                <div
                                    className='flex items-center text-[24px] bg-slate-200 border-2 border-slate-300 px-5 py-2 rounded-full'>
                                    <p title='مبدا' className=''>{dataInfo?.origin_city?.name} </p> <span
                                    className='px-2 text-slate-500'>...............</span> <p
                                    title='مقصد'> {dataInfo?.destination_city?.name}</p>
                                </div>
                            </div>

                            <div className='flex flex-col w-full pt-5'>

                                <div className='flex gap-x-2 mb-5'>
                                    <img src={image} className='w-[35px]'/>
                                    <h2 className='text-[24px]'>اطلاعات سفر</h2>
                                </div>

                                <p className='text-slate-700'><span
                                    className='text-black'>آدرس : </span>{dataInfo ? dataInfo.address : "-"}</p>
                                <div
                                    className='flex items-center justify-between gap-y-2 mt-5 md:flex-col md:items-start'>
                                    <p className='text-slate-700'><span
                                        className='text-black'>تعداد مسافر : </span>{dataInfo ? dataInfo.passenger_count : "-"}
                                    </p>
                                    <p className='text-slate-700'><span
                                        className='text-black'>تاریخ :‌ </span>{mom(dataInfo?.travel_date.slice(0, 10), 'YYYY/MM/DD').locale('fa').format('jYYYY/jM/jD')}
                                    </p>
                                </div>

                                <div className='flex items-center justify-between gap-y-2 md:flex-col md:items-start'>
                                    <p className={`${dataInfo?.status !== "payment_required" && dataInfo?.status !== "created" ? "text-darkBlue" : "text-red-600"}`}>
                                        <span
                                            className='text-black'>وضعیت سفر : </span>
                                        <span
                                            className="text-blue-500"> {(dataInfo?.status === "payment_completed" && "پرداخت شده")} </span>
                                        <span
                                            className="text-green-500"> {(dataInfo?.status === "completed" && "کامل انجام شده")}</span>
                                        <span
                                            className="text-yellow-600"> {dataInfo?.status === "payment_required" && "در انتظار پرداخت"} </span>
                                        <span
                                            className="text-red-500"> {(dataInfo?.status === "canceled" && "لغو شده")} </span>
                                        <span
                                            className="text-cyan-500"> {(dataInfo?.status === "active" && "فعال")} </span>
                                        <span
                                            className="text-yellow-600"> {(dataInfo?.status === "created" && "اطلاعات ناقص")} </span>
                                    </p>
                                    <p className='text-slate-700'><span
                                        className='text-black'>نوع ماشین :‌ </span>{dataInfo && dataInfo.car_type ? dataInfo.car_type.name : "-"}
                                    </p>
                                </div>


                                <div
                                    className='flex items-center justify-between gap-y-2 mt-1 md:flex-col md:items-start'>
                                    <p className='text-slate-700'><span
                                        className='text-black'>زمان سفر </span>:‌ {dataInfo ? dataInfo.travel_hour_time : "-"}
                                    </p>
                                    <p className='text-slate-700'>
                                        <span
                                            className='text-black'>قیمت : </span>{totalPrice ?? 0}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* --- اطلاعات مسافر --- */}

                        <div className='w-full flex flex-col border-b-2 border-slate-300 py-5'>

                            <div className='flex flex-col w-full pt-5'>

                                <div className='flex gap-x-2 mb-5'>
                                    <img src={passenger} className='w-[35px]'/>
                                    <h2 className='text-[24px]'>اطلاعات مسافر</h2>
                                </div>

                                <p className='text-slate-700'><span
                                    className='text-black'>آدرس مسافر: </span>{dataInfo && dataInfo.passenger_snapshot ? dataInfo.passenger_snapshot.address ?? "-" : dataInfo?.passenger?.address ?? "-"}
                                </p>

                                <div
                                    className='flex items-center justify-between gap-y-2 mt-5 md:flex-col md:items-start'>
                                    <p className='text-slate-700'><span
                                        className='text-black'>نام مسافر : </span>{dataInfo && dataInfo.passenger_snapshot ? dataInfo.passenger_snapshot.full_name ?? "-" : dataInfo?.passenger?.full_name ?? "-"}
                                    </p>
                                    <p className='text-slate-700'><span
                                        className='text-black'>کدملی :‌ </span>{dataInfo && dataInfo.passenger_snapshot ? dataInfo.passenger_snapshot.national_code ?? "-" : dataInfo?.passenger?.national_code ?? "-"}
                                    </p>
                                </div>

                                <div className='flex items-center justify-between gap-y-2 md:flex-col md:items-start'>
                                    <p className='text-slate-700'><span
                                        className='text-black'>نام پدر : </span>{dataInfo && dataInfo.passenger_snapshot ? dataInfo.passenger_snapshot.father_name ?? "-" : dataInfo?.passenger?.father_name ?? "-"}
                                    </p>
                                    <p className='text-slate-700'><span
                                        className='text-black'>شماره تماس :‌ </span>{dataInfo && dataInfo.passenger_snapshot ? `0${dataInfo.passenger_snapshot.mobile}` ?? "-" : `0${dataInfo?.passenger?.mobile}` ?? "-"}
                                    </p>
                                </div>

                            </div>
                        </div>

                        {
                            dataInfo?.status === "payment_required" ?
                                <div
                                    className='flex items-center py-5 w-fit mx-auto gap-x-5 md:flex-col md:gap-y-2 md:items-stretch'>
                                    <button onClick={() => setPayTripModal(true)}
                                            className='border-2 border-green-600 text-green-600 px-5 py-2 rounded-lg hover:bg-green-600 hover:text-white duration-[0.4s]'
                                    >
                                        پرداخت {priceToPay ?? 0} تومان
                                    </button>

                                    <button onClick={() => navigate(-1)}
                                            className='border-2 border-blue-600 text-blue-600 px-5 py-2 rounded-lg hover:bg-blue-600 hover:text-white duration-[0.4s]'
                                    >بازگشت
                                    </button>
                                </div>

                                :
                                dataInfo?.status === "created" ?
                                    <div
                                        className='flex items-center py-5 w-fit mx-auto gap-x-5 md:flex-col md:gap-y-2 md:items-stretch'>
                                        <button onClick={() => navigate(`/passenger/${specificTripId.id}`)}
                                                className='border-2 border-green-600 text-green-600 px-5 py-2 rounded-lg hover:bg-green-600 hover:text-white duration-[0.4s]'
                                        >
                                            تکمیل اطلاعات
                                        </button>

                                        <button onClick={() => navigate(-1)}
                                                className='border-2 border-blue-600 text-blue-600 px-5 py-2 rounded-lg hover:bg-blue-600 hover:text-white duration-[0.4s]'
                                        >بازگشت
                                        </button>
                                    </div>
                                    :
                                    dataInfo?.status === "canceled" ?
                                        <div
                                            className="flex items-center py-5 w-fit mx-auto gap-x-5 md:flex-col md:gap-y-2 md:items-stretch ">
                                            <button onClick={() => navigate(-1)}
                                                    className=' border-2 border-blue-600 text-blue-600 px-5 py-2 rounded-lg hover:bg-blue-600 hover:text-white duration-[0.4s]'
                                            >
                                                بازگشت
                                            </button>
                                        </div>
                                        :

                                        <div
                                            className='flex items-center py-5 w-fit mx-auto gap-x-5 md:flex-col md:gap-y-2 md:items-stretch'>
                                            <button onClick={() => setCancelTripModal(true)}
                                                    className='border-2 border-red-600 text-red-600 px-5 py-2 rounded-lg hover:bg-red-600 hover:text-white duration-[0.4s]'
                                            >
                                                لغو سفر
                                            </button>

                                            <button onClick={() => navigate(`/trip/${specificTripId.id}`)}
                                                    className='border-2 border-blue-600 text-blue-600 px-5 py-2 rounded-lg hover:bg-blue-600 hover:text-white duration-[0.4s]'
                                            >
                                                نمایش سفر
                                            </button>
                                            <button onClick={() => navigate('/passengersTrips')}
                                                    className='border-2 border-blue-600 text-blue-600 px-5 py-2 rounded-lg hover:bg-blue-600 hover:text-white duration-[0.4s]'
                                            >
                                                بازگشت
                                            </button>
                                        </div>

                        }
                    </div>
                }


                {/* cancel trip modal  */}
                <Modal
                    isOpen={cancelTripModal}
                    onRequestClose={handleCancel}
                    style={customStyles}
                    className="modalMobile w-full max-w-[500px]"
                >

                    <div className='h-full flex flex-col'>
                        <div className=' p-2'>
                            <div className='flex items-center gap-x-2 border-b-2 border-red-400'>
                                <FaTrash className='text-red-600'/>
                                <p className='text-red-600'>لغو سفر</p>
                            </div>
                            <div className='flex flex-col justify-center items-center mt-5 px-10'>
                                <p>آیا مایل به لغو سفر هستید ؟</p>
                                <p>بعد از لغو سفر هزینه پرداخت شده به حساب شما بر نمیگردد</p>
                            </div>
                            <div className='flex items-center justify-between my-5 px-10'>
                                <button onClick={cancelTrip}
                                        className='px-5 py-2 rounded-lg text-darkBlue border-2 border-darkBlue'>بله
                                </button>
                                <button onClick={() => setCancelTripModal(false)}
                                        className='px-5 py-2 rounded-lg text-red-500 border-2 border-red-500'>خیر
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>


                {/* pay trip modal  */}
                <Modal
                    isOpen={payTripModal}
                    onRequestClose={handleCancel}
                    style={customStyles}
                    className="modalMobile w-full max-w-[500px]"
                >

                    <div className='h-full flex flex-col'>
                        {/* <button className="custom-modal-close" onClick={handleCancel}>
            &times;
          </button> */}

                        <div className=' p-2'>
                            <div className='flex items-center gap-x-2 border-b-2 border-emerald-400'>
                                <GiTwoCoins className='text-emerald-600'/>
                                <p className='text-emerald-600'>پرداخت هزینه</p>
                            </div>
                            <div className='mt-5 px-10'>
                                <p>آیا مایل به پرداخت هزینه این سفر هستید ؟</p>
                            </div>
                            <div className='flex items-center justify-between my-5 px-10'>
                                <button onClick={handleClick}
                                        className='px-5 py-2 rounded-lg text-darkBlue border-2 border-darkBlue'>بله
                                </button>
                                <button onClick={() => setPayTripModal(false)}
                                        className='px-5 py-2 rounded-lg text-red-500 border-2 border-red-500'>خیر
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default SingleTripDetails
