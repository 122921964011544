import React, { useContext, useEffect, useState } from "react";
import Header2 from "../header/Header2";
import { MapComponent, MapTypes } from "@neshan-maps-platform/mapbox-gl-react";
import nmp_mapboxgl from "@neshan-maps-platform/mapbox-gl";
import axios from "axios";
import { useSelector } from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { StateApp } from "../context/Context";
import Loading from "../Loading/Loading";
import { message, notification } from "antd";
import { url } from "../url";
import { TfiFullscreen } from "react-icons/tfi";

const Address = () => {
    const savedLatitude = localStorage.getItem('latitude');
    const savedLongitude = localStorage.getItem('longitude');

    const travel = useParams();

    const { latitude, setLatitude } = useContext(StateApp);
    const { longitude, setLongitude } = useContext(StateApp);
    const [access, setAccess] = useState(false);
    const [travelAddress, setTravelAddress] = useState("");
    const travelInfo = useSelector((state) => state.travelinformation.travelInfo);
    const { travelId } = travelInfo;
    const { userInfo } = useSelector((state) => state.userinformation);
    const { accessToken } = userInfo;
    const navigate = useNavigate(null);
    const { loading, setLoading } = useContext(StateApp);
    const { tripPrice, setTripPrice } = useContext(StateApp);

    useEffect(() => {
        localStorage.setItem('latitude', latitude);
        localStorage.setItem('longitude', longitude);
    }, [latitude, longitude]);

    useEffect(() => {
        const neshanMap = new nmp_mapboxgl.Map({
            mapType: nmp_mapboxgl.Map.mapTypes.neshanVector,
            container: "map",
            zoom: 7,
            pitch: 0,
            center: [longitude, latitude],
            minZoom: 2,
            maxZoom: 21,
            trackResize: true,
            mapKey: "web.cc0fdd0ea7ef4a7d877e3c01e54fe377",
            poi: true,
            traffic: false,
            isZoomControlsEnabled: true,
            setZoomGesturesEnabled: true,
            mapTypeControllerOptions: {
                show: false,
            },
        });
        const marker = new nmp_mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .addTo(neshanMap);

        neshanMap.on('click', (e) => {
            const { lng, lat } = e.lngLat;
            setLongitude(lng < 0 ? `${lng}` : `+${lng}`);
            setLatitude(lat < 0 ? `${lat}` : `+${lat}`);
            marker.setLngLat([lng, lat]);
        });

        neshanMap.addControl(
            new nmp_mapboxgl.NavigationControl(),
            "top-right"
        );

    }, [longitude, latitude]);

    const handlesubmit = () => {
        setLoading(true);
        axios.put(`${url}/travel/v1/set-address-and-location-for-travel`, {
            travel_id: travel.travelID,
            address: travelAddress,
            location: {
                latitude: latitude,
                longitude: longitude
            }
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => {
                const { price, passenger, travel_date, origin_city, destination_city, travel_hour_time, car_type } = response.data.data;
                const travelData = {
                    price,
                    passenger,
                    travel_date,
                    origin_city,
                    destination_city,
                    travel_hour_time,
                };
                localStorage.setItem('travelData', JSON.stringify(travelData));
                setTripPrice(price);
                window.scrollTo(0, 0);
                setLoading(false);
                localStorage.setItem("travelID",travel.travelID);
                navigate(`/Payment`);
            })
            .catch(error => {
                console.error("Error reserving car:", error);
                notification.error({
                    message: error.response.data.message,
                    description: "",
                    placement: 'topRight',
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <div>
                <div className="md:hidden">
                    <Header2 />
                </div>
                <div className="flex justify-center items-center">
                    <div className="w-[90%] h-[64px] items-center md:w-[95%]">
                        <div className="flex items-center h-full">
                            <p className="text-[22px]">
                                ثبت آدرس
                            </p>
                        </div>
                        <div className="w-full bg-white rounded-xl p-6 md:p-2">
                            <div>
                                <p className="mb-3">انتخاب روی نقشه</p>
                                <div className="flex justify-center">
                                    <div className="w-[90%] h-96 justify-center flex rounded-lg md:w-[95%] relative">
                                        <MapComponent
                                            id="map"
                                            className="rounded-md"
                                            options={{
                                                width: "300px",
                                                height: "200px",
                                                mapKey: "web.cc0fdd0ea7ef4a7d877e3c01e54fe377",
                                                mapType: MapTypes.neshanVector,
                                                mapTypeControllerOptions: {
                                                    show: false,
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <div>
                                    <p>نوشتن آدرس</p>
                                </div>
                                <div className="w-[100%] h-40 justify-center flex rounded-lg">
                                    <textarea
                                        onChange={(e) => setTravelAddress(e.target.value)}
                                        className="w-[90%] h-32 mt-2 p-2 border border-gray-300 rounded-lg resize-none"
                                        placeholder="آدرس خود را وارد کنید"
                                    ></textarea>
                                </div>
                            </div>
                            <div onClick={handlesubmit} className="flex justify-center items-center">
                                <button
                                    className="bg-[#4F48EC] md:w-[90%] w-[600px] h-[60px] md:h-[44px] rounded-[10px] flex justify-center items-center">
                                    {loading ? (<Loading />) : (<div>
                                        <p className="text-[26px] md:text-[22px] text-white">
                                            ثبت اطلاعات
                                        </p>
                                    </div>)}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Address;
