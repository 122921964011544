import React, { useContext, useEffect, useState } from "react";
import Profile from "../header/public/profile.png";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { FaMobileAlt } from "react-icons/fa";
import { PiSuitcaseRollingBold } from "react-icons/pi";
import icon1 from "../header/public/svgexport-3 1.png";
import icon2 from "../header/public/svgexport-11.svg";
import { IoCarSportOutline } from "react-icons/io5";
import { BiChevronDown, BiSupport } from "react-icons/bi";
import { useSelector } from "react-redux";
import icon3 from "./public/svgexport-9.svg";
import LoginRegister from "../Login/LoginRegister";
import SuccessLogin from "../Modal/Success Login";
import { StateApp } from "../context/Context";
import HeaderPicture from "./public/ra4 1.png";
import styled from "styled-components";
import UpdateProfile from "../Login/UpdateProfile";
import Modal from "react-modal";
import { RxExit } from "react-icons/rx";
import logo1 from "../public/maranco2.png";
import { IoClose } from "react-icons/io5";
import logo from '../public/logo (2).png'
import { useNavigate } from "react-router-dom";
import TransactionIcon from "../header/public/Transaction.svg";

// ---- icons :
import { MdModeOfTravel } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { HiHome } from "react-icons/hi2";
import { IoInformationCircle } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { FaTaxi } from "react-icons/fa";
import { MdPolicy } from "react-icons/md";
import { padding } from "@mui/system";
import { GrTransaction } from "react-icons/gr";

const Navbar = () => {
    const { openLogin, showSuccessLogin, setshowSuccsessLogin, setShowNavbar } = useContext(StateApp);
    const [isDownloadAppModalOpen, setIsDownloadAppModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showManegeProfile, setShowManegeProfile] = useState(false);

    const navigate = useNavigate(null);

    const showManageModal = () => {
        setShowManegeProfile(true);
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        // window.location.reload();
        setIsModalOpen(false);
    };

    const { userInfo, error } = useSelector((state) => state.userinformation);
    const { name, idCode, birthDate, gender, address, status, accessToken, mobile, loginInfo } = userInfo;
    const [token, setToken] = useState();

    useEffect(() => {
        setToken(accessToken);
    }, [accessToken]);
    const logOut = () => {
        localStorage.removeItem("accessToken");
        // window.location.reload();
        setshowSuccsessLogin(false)
        navigate("/")
    };

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: "0px 0px 0px 0px",
            transform: "translate(-50%, -50%)",
            position: "fixed",
            borderRadius: "10px",
            outline: "none",
            border: "none",
            zIndex: "9999",
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            height: "100vh",
            backdropFilter: "blur(5px)",
        },
    };

    const downloadAppModalStyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            position: "fixed",
            borderRadius: "10px",
            padding: "60px 10px",
            outline: "none",
            backgroundColor: "rgba(0,0,0,0.5)",
            border: "none",
            zIndex: "9999",
            width: "90%",
            height: "fit-content",
            maxWidth: "800px"
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            height: "100vh",
            backdropFilter: "blur(5px)",
        },
    };

    return (
        <>
            <Modal isOpen={isModalOpen} onRequestClose={handleCancel} style={customStyles} className="modalMobile">
                <div className="">
                    <IoClose size={26} className="custom-modal-close" onClick={handleCancel} />
                </div>
                {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : null}
            </Modal>

            {
                showManegeProfile && (
                    <div>
                        <Modal isOpen={isModalOpen} onRequestClose={handleCancel} style={customStyles} className="modalMobile">
                            <div className="">
                                <IoClose size={26} className="custom-modal-close" onClick={handleCancel} />
                            </div>
                            <UpdateProfile />
                        </Modal>
                    </div>
                )
            }
            <div className="">
                <div className="w-auto bg-white rounded-l-[10px] h-[100vh]">
                    <div className="flex-col flex justify-center items-center h-auto gap-4 w-auto">
                        <div className="mt-8 flex flex-col items-center justify-center cursor-pointer">
                            <div className={`${loginInfo ? "" : "hidden"}`}>
                                {token ? (
                                    <div onClick={showManageModal}>
                                        <p className="cursor-pointer text-black flex items-center gap-x-5 rounded-md p-3 text-[18px]">
                                            {name ? name : mobile} <FiEdit3 />
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                        <p className="w-fit" onClick={() => setIsModalOpen(true)}>ورود حساب کاربری</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="border-gray-300 border-t-2 w-[90%] mt-4"></div>
                        <div className="mt-4 mr-3 w-full">
                            <ul className="text-black flex flex-col gap-[30px] w-[100%]">
                                <div className="cursor-pointer flex items-center gap-3 no-select">
                                    <HiHome className="text-[22px]" />
                                    <p className="text-[16px] text-[400]" onClick={() => { navigate('/'); setShowNavbar(false) }}>صفحه اصلی</p>
                                </div>

                                <div onClick={() => setIsDownloadAppModalOpen(true)} className="cursor-pointer flex items-center gap-3 no-select">
                                    <span>
                                        <FaMobileAlt style={{ fontSize: "22px" }} />
                                    </span>
                                    <p className="text-[16px] text-[400]">دریافت اپلیکیشن</p>
                                </div>

                                <div className="cursor-pointer flex items-center gap-3 no-select">
                                    <MdModeOfTravel className="text-[22px]" />
                                    <p className="text-[16px] text-[400]" onClick={() => { navigate('/passengersTrips'); setShowNavbar(false) }}>سفرهای من</p>
                                </div>
                                <div className="cursor-pointer flex items-center gap-3 no-select">
                                    <GrTransaction className="text-[22px]" />
                                    <p className="text-[16px] text-[400]" onClick={() => { navigate('/transactions'); setShowNavbar(false) }}>تراکنش ها</p>
                                </div>

                              

                                <div onClick={() => { navigate("/support"); setShowNavbar(false) }} className="cursor-pointer flex items-center gap-3 no-select">
                                    <span>
                                        <BiSupport style={{ fontSize: "22px" }} />
                                    </span>
                                    <p className="text-[16px] text-[400]">پشتیبانی</p>
                                </div>
                              

                                <div className="cursor-pointer flex items-center gap-3 no-select">
                                    <IoInformationCircle className="text-[22px]" />
                                    <a href="https://marankoo.ir/about-us/" className="text-[16px] text-[400]">درباره ما</a>
                                </div>

                                <div onClick={() => { navigate("/rules"); setShowNavbar(false) }} className="cursor-pointer flex items-center gap-3 no-select">
                                    <span>
                                        <MdPolicy style={{ fontSize: "22px" }} />
                                    </span>
                                    <p className="text-[16px] text-[400]">قوانین</p>
                                </div>
                                {/* <div className="cursor-pointer flex items-center gap-3 no-select">
                                    <BsBorderStyle /><p className="text-[16px] text-[400]">وضعیت سفارش</p>
                                </div> */}
                                {/* <div className="cursor-pointer flex items-center gap-3 no-select">
                                    <span>
                                        <IoCarSportOutline style={{ fontSize: "22px" }} />
                                    </span>
                                    <p className="text-[16px] text-[400]">خودرو ها</p>
                                </div> */}

                            </ul>
                        </div>
                    </div>
                    <Modal
                        isOpen={isDownloadAppModalOpen}
                        onRequestClose={handleCancel}
                        style={downloadAppModalStyle}
                        className="modalMobile"
                    >

                        <div className=''>
                            <button className="custom-modal-close" onClick={() => setIsDownloadAppModalOpen(false)}>
                                &times;
                            </button>
                            <div className="flex justify-center items-center">
                                <div className="flex items-center gap-5 flex-col">
                                    <a href="https://marankoo.ir/wp-content/uploads/android/maranko-driver.apk" className="flex items-center w-full gap-x-2 bg-yellow-300 p-6 rounded-[10px]"><FaTaxi className="text-[34px] mb-2" />دریافت مستقیم اپلیکیشن راننده </a>
                                    <a href="https://marankoo.ir/wp-content/uploads/android/maranko-passenger.apk" className="flex items-center w-full gap-x-2 bg-blue-400 p-6 rounded-[10px]"><FaUsers className="text-[34px] mb-2" />دریافت مستقیم اپلیکیشن مسافر</a>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    {token && (
                        <div>
                            <div className="flex justify-center">
                                <div className="border-gray-300 border-t-2 w-[90%] mt-6 justify-self-center"></div>
                            </div>
                            <div className="mr-2">
                                <div className="cursor-pointer flex items-center gap-3 mt-6" onClick={logOut}>
                                    <RxExit size={22} className="text-red-700 cursor-pointer" />
                                    <p className="font-medium cursor-pointer text-[16px] text-red-700 no-select">خروج از حساب كاربری</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mt-12 flex justify-center">
                        <img className="w-[128px]" src={logo1} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
