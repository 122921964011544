import React, { useContext, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Main from "./Main";
import Login from "../src/Login/Login";
import AllErrors from './ErrorPages/AllErrors';
import NotFoundPage from './ErrorPages/NotFoundPage';
import CreateAccount from "./Login/CreateAccount";
import LoginRegister from "./Login/LoginRegister";
import Verification from "../src/Login/VerificationCode";
import PassengerInformation from "./PassengerInformation/PassengerInformation";
import Trip from "../src/TripPage/Trip";
import ChatPage from './TripPage/ChatPage';
import StopOnWay from './TripPage/StopOnWay';
import Address from "./Address/Address";
import Payment from "./Payment/Payment";
import PaymentSuccess from "./Payment/PaymentSuccess";
import PassengersTrips from './PassengerTrip/PassengersTrips';
import { StateApp } from './context/Context';
import RulesPage from './RulesPage/RulesPage';
import { requestForToken, onMessageListener, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import SupportPage from './SupportPage/SupportPage';
import UserTickets from './SupportPage/UserTickets';
import GetProfile from './profile/GetProfile'
import { LuMenu } from 'react-icons/lu'
import { IoMdClose } from "react-icons/io";
import Navbar from './header/Navbar';
import { AnimatePresence, motion } from 'framer-motion'
import SingleTripDetails from './PassengerTrip/SingleTripDetails';
import Wallet from './Payment/Wallet';
import Transactions from './Transactions/Transactions';
function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const { travelData, setTravelData } = useContext(StateApp);
    const [temp, setTemp] = useState(false)
    const [token, setToken] = useState("")
    const { ShowNavbar, setShowNavbar } = useContext(StateApp);
    const handleNavbar = () => {
        setShowNavbar(!ShowNavbar);
    };

    
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function (registration) {
            console.log('Service Worker registered with scope: ', registration.scope);
        }).catch(function (error) {
            console.log('Service Worker registration failed: ', error);
        });
    }

    // const sendTokenToAPI = async (token) => {
    //     try {
    //         const response = await axios.post('https://your-api-endpoint.com/push-token', {
    //             token: token,
    //         });

    //         console.log("Token successfully sent to API:", response.data);
    //         setIsTokenSent(true);
    //     } catch (error) {
    //         console.error("Error sending token to API:", error);
    //     }
    // };

    async function setup() {
        const push = await requestForToken();
        setToken(push)
        // console.log(push)
        onMessage(messaging, (pay) => {
            console.log(pay);
        });
        if (push) {
            // sendTokenToAPI(pushToken); // ارسال توکن به سرور
        }
    }
    

    useEffect(() => {
        setup()
        
        // setTemp((prev)=> !prev)
        // return ()=>{setToken("")}
    }, []);


    // useEffect(() => {
    //     const storedTravelData = localStorage.getItem('travelData');
    //     if (storedTravelData) {
    //         setTravelData(JSON.parse(storedTravelData));
    //         navigate("/payment");
    //     }
    // }, [navigate, setTravelData]);

    // useEffect(() => {
    //     handleNavbar()
    // }, [location.pathname])

    return (
        <div className='relative'>
            <div onClick={handleNavbar} className={`hidden md:flex w-[40px] ${location.pathname === "/" ? "h-0 top-6 right-1" : "h-fit top-2 right-2"} overflow-visible items-center justify-center sticky  z-50`}>
                <div className='bg-darkBlue drop-shadow-md rounded-md'>
                    <LuMenu className='text-white text-[35px] p-2 ' />
                </div>
            </div>
            <>

                {ShowNavbar && (
                    <div className="fixed h-[100vh] top-0 inset-0 z-40 bg-black bg-opacity-60" onClick={handleNavbar}></div>
                )}
                <div className="fixed z-50 grid grid-cols-12 w-full top-0">
                    <div className="col-span-8">
                        <AnimatePresence>
                            {ShowNavbar && (
                                <motion.div
                                    initial={{ x: window.innerWidth }}
                                    animate={{ x: 0 }}
                                    exit={{ x: window.innerWidth }}
                                    transition={{
                                        type: 'spring',
                                        stiffness: 350,
                                        damping: 35,
                                        duration: 0.5
                                    }}
                                    key={ShowNavbar}
                                    className="h-full"
                                >
                                    <Navbar />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                    <div onClick={handleNavbar} className="col-span-4"></div>
                </div>
                <div>
                    <GetProfile />
                </div>
            </>

            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/LoginRegister" element={<LoginRegister />} />
                <Route path="/notFound" element={<NotFoundPage />} />
                <Route path="/errorPage" element={<AllErrors />} />
                <Route path="/CreateAccount" element={<CreateAccount />} />
                <Route path="/Verification" element={<Verification />} />
                <Route path="/messagePage" element={<ChatPage />} />
                <Route path="/optionPage" element={<StopOnWay />} />
                <Route path="/passenger/:travelID" element={<PassengerInformation />} />
                <Route path="/trip/:id" element={<Trip />} />
                <Route path="/passengersTrips" element={<PassengersTrips />} />
                <Route path="/passengersTrips/:id" element={<SingleTripDetails />} />
                <Route path="/address/:travelID" element={<Address />} />
                <Route path="/Payment" element={<Payment />} />
                <Route path="/successPayment" element={<PaymentSuccess />} />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/userTickets" element={<UserTickets />} />
                <Route path="/rules" element={<RulesPage />} />
                <Route path="/walletValue" element={<Wallet />} />
                <Route path="/transactions" element={<Transactions />} />
            </Routes>
        </div >
    );
}

export default App;
