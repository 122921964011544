import React, { useEffect } from 'react'
import { TfiWallet } from "react-icons/tfi";
import { useNavigate } from 'react-router-dom';
import Modal from "react-modal";
import { useState } from 'react';
import { HiMiniPlusCircle } from "react-icons/hi2";
import Header2 from '../header/Header2';
import axios from 'axios';
import { url } from '../url';
// import { DotLottieReact } from '@lottiefiles/dotlottie-react';


const Wallet = () => {

  const navigate = useNavigate(null)
  const [showWalletValueModal, setShowWalletValueModal] = useState(false);
  const [noValue, setNoValue] = useState(false)
  const [walletValue, setWalletValue] = useState("")
  const [walletCharge, setWalletCharge] = useState("")

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      backgroundColor: "white",
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      backgroundColor: "white",
      outline: 'none',
      border: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };

  useEffect(() => {
    axios.get(`${url}/transaction/get-wallet-balance`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    })
      .then(res => {
        setNoValue(false)
        setWalletValue(res.data.data)
        console.log(res.data);
      }).catch(err => {
        setNoValue(true)
      })
  }, [])

  return (
    <div className='w-full h-full flex flex-col justify-between'>
      <div className={`md:hidden z-50`}>
        <Header2 />
      </div>
      <div className='w-[80%] h-full mx-auto my-10 bg-white drop-shadow-lg rounded-xl p-10 md:w-full md:drop-shadow-none md:mx-0 md:p-2'>
        <div className='flex items-center gap-x-2'>
          <TfiWallet className='text-[24px]' />
          <h2 className='font-bold text-[24px]'>کیف پول من</h2>
        </div>

        <div className='flex items-center justify-between border-2 border-slate-400 rounded-[10px] w-[40%] min-w-[250px] px-5 py-10 mx-auto mt-5'>
          <p className=''>اعتبار شما :‌ </p>
          <p>تومان {walletValue} </p>
        </div>

        <div className='h-full flex flex-col justify-between px-4 py-2'>
          <div>
            <p className='border-r-4 border-emerald-500 pr-2'>افزایش اعتبار</p>

            <div className='rounded-[10px] bg-slate-200 px-5 py-2 flex gap-x-2 items-center mt-5 md:flex-col md:items-start'>
              <p className='text-nowrap flex-grow-0 '>مقدار مورد نظر :‌</p>

              <input
                onChange={(e) => setWalletCharge(e.target.value)}
                value={walletCharge}
                type='number'
                placeholder='مقدار مورد نظر را به تومان وارد کنید...'
                className='w-full bg-transparent border-b-2 border-slate-400 outline-none text-left placeholder:text-right' />
            </div>

            <p className='mt-10'>مقدار پیشنهادی</p>

            <div className='flex flex-col mx-auto h-[200px] w-full my-2 bg-slate-100 overflow-y-auto p-2'>
              <div onClick={() => setWalletCharge(5000)} className='cursor-pointer flex items-center gap-x-2 hover:bg-emerald-100 p-2 '>
                <HiMiniPlusCircle className='text-emerald-700 text-[18px]' />
                <p className='text-emerald-600'>تومان ۵.۰۰۰</p>
              </div>
              <div onClick={() => setWalletCharge(10000)} className='cursor-pointer flex items-center gap-x-2 hover:bg-emerald-100 p-2 '>
                <HiMiniPlusCircle className='text-emerald-700 text-[20px]' />
                <p className='text-emerald-600'> تومان ۱۰.۰۰۰</p>
              </div>
              <div onClick={() => setWalletCharge(20000)} className='cursor-pointer flex items-center gap-x-2 hover:bg-emerald-100 p-2 '>
                <HiMiniPlusCircle className='text-emerald-700 text-[20px]' />
                <p className='text-emerald-600'>تومان ۲۰.۰۰۰</p>
              </div>
              <div onClick={() => setWalletCharge(50000)} className='cursor-pointer flex items-center gap-x-2 hover:bg-emerald-100 p-2 '>
                <HiMiniPlusCircle className='text-emerald-700 text-[20px]' />
                <p className='text-emerald-600'>تومان ۵۰.۰۰۰</p>
              </div>
              <div onClick={() => setWalletCharge(100000)} className='cursor-pointer flex items-center gap-x-2 hover:bg-emerald-100 p-2 '>
                <HiMiniPlusCircle className='text-emerald-700 text-[20px]' />
                <p className='text-emerald-600'>تومان ۱۰۰.۰۰۰</p>
              </div>
              <div onClick={() => setWalletCharge(200000)} className='cursor-pointer flex items-center gap-x-2 hover:bg-emerald-100 p-2 '>
                <HiMiniPlusCircle className='text-emerald-700 text-[20px]' />
                <p className='text-emerald-600'>تومان ۲۰۰.۰۰۰</p>
              </div>
            </div>

            {/* <div className='w-full flex items-center justify-between my-5'>
              <button
                onClick={() => {
                  setShowWalletValueModal(false)
                  setWalletValue(null)
                }}
                className='border-2 border-emerald-600 rounded-lg px-4 py-2 text-emerald-600 hover:text-white hover:bg-emerald-600 duration-[0.2s]'>پرداخت</button>
              <button onClick={() => {
                setShowWalletValueModal(false)
                setWalletValue(null)
              }} className='border-2 border-darkBlue rounded-lg px-4 py-2 text-darkBlue hover:text-white hover:bg-darkBlue duration-[0.2s]'>انصراف</button>
            </div> */}
          </div>
        </div>

        <div className='flex items-center justify-between mt-32 md:mt-0 gap-x-5 gap-y-2 flex-wrap'>
          <button onClick={() => setShowWalletValueModal(true)} className='text-emerald-600 border-2 border-emerald-600 px-4 py-2 rounded-lg hover:text-white hover:bg-emerald-600 duration-[0.4s]'>افزایش اعتبار</button>
          <button className='text-blue-600 border-2 border-blue-600 px-4 py-2 rounded-lg hover:text-white hover:bg-blue-600 duration-[0.4s]' onClick={() => navigate("/")}>بازگشت به صفحه اصلی</button>
        </div>

      </div>
    </div>
  )
}

export default Wallet
