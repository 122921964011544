import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCalendar } from "react-icons/bs";
import "../body/bodypage.css";
import { IoLocationOutline } from "react-icons/io5";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import persian_en from "react-date-object/locales/persian_en"
import transition from "react-element-popper/animations/transition"
import opacity from "react-element-popper/animations/opacity";
import axios from "axios";
import Details from "./public/details";
import "react-multi-date-picker/styles/layouts/mobile.css"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../slices/UserInformation";
import { setTravel } from "../slices/Travelinfo";
import Cookies from "js-cookie";
import { StateApp } from "../context/Context";
import Loading from "../Loading/Loading";
import { message, notification } from "antd";
import Modal from "react-modal";
import LoginRegister from "../Login/LoginRegister";
import SuccessLogin from "../Modal/Success Login";
import { url } from "../url";
import { TbLoader2 } from "react-icons/tb";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";




const Body = () => {
    const [counter, setCounter] = useState(1);
    const [searchVal, setSearchVal] = useState("");
    const [searchDestination, setSearchDestination] = useState("");
    const [inputVal, setInputVal] = useState("");
    const [inputValDestination, setInputValDestination] = useState("");
    const [openLocation, setOpenLocation] = useState(false);
    const [openDestination, setopenDestination] = useState(false);
    const [cityName, setCityName] = useState([]);
    const [value, setValue] = useState(new Date());
    const [showCartype, setShowCartype] = useState(false);
    const [selectCartype, setSelectCartype] = useState("");
    const navigate = useNavigate(null);
    const [locationId, setlocationId] = useState()
    const [destanationId, setDestanationId] = useState()
    const { userInfo, error } = useSelector((state) => state.userinformation);
    const { accessToken } = userInfo;
    const [openTimePicker, setOpenTimePicker] = useState(false);
    const [selectedHour, setSelectedHour] = useState(null);
    const [selectedMinute, setSelectedMinute] = useState(null);
    const [time, setTime] = useState("انتخاب ساعت");
    const [carTypeMenu, setCarTypeMenu] = useState(false);
    const travelInfo = useSelector((state) => state.travelinformation.travelInfo);
    const [allCarTypes, setAllCarTypes] = useState([]);
    const { travelId, origin, destination } = travelInfo;
    const dispatch = useDispatch();
    const {
        loading,
        setLoading,
        openLogin,
        showSuccessLogin,
        updateUserName_header,
        setUpdateUserName_header
    } = useContext(StateApp);
    const [categoryLoading, setCategoryLoading] = useState(true);
    const [cityLoading, setCityLoading] = useState(true);
    const [gregorianDate, setgregorianDate] = useState("")
    const [accpetTravel, setAcceptTravel] = useState(false)
    const [categories, setCategories] = useState("")

    // car list 
    const [vipCars, setVipCars] = useState([])
    const [cipCars, setCipCars] = useState([])
    const [eghtesadiCars, setEghtesadiCars] = useState([])
    const [mianRadeCars, setMianRadeCars] = useState([])
    const [standardCard, setStandardCars] = useState([])

    if (localStorage.getItem("antdUpdate") === "true") {
        message.success("اطلاعات با موفقیت ذخیره شد!")
    }

    // console.log('mian rade car : ', mianRadeCars);

    useEffect(() => {
        setLoading(false)
        axios.get(`${url}/states/v1/get-city-list`, {
            params: {
                withoutPagination: true,
            }
        })
            .then((res) => {
                const data = res.data.data;
                setCityName(data);
            })
            .catch((error) => {
                if (error.status !== 200)
                    setCityLoading(false);
                else
                    setCityLoading(true);
            });


        // car categories 
        axios.get(`${url}/car-category/v1/get-car-category-list`)
            .then(res => {
                setCategories(res.data.data)
                // console.log('categories : ', res.data);


            }).catch(err => {
            })


        // vip
        axios.get(`${url}/cars/get-car-list-paginate`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                limit: 3,
                category: "66b47a35f101b5ec2dfac690"
            }
        })
            .then(res => {
                setVipCars(res.data.data.docs)
                // console.log(res.data.data)
            }).catch(err => {

            })

        // mian rade
        axios.get(`${url}/cars/get-car-list-paginate`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                limit: 3,
                category: "66c5cbdf3bf75670c1252fae"
            }
        })
            .then(res => {
                setMianRadeCars(res.data.data.docs)
                // console.log('mian rade api', res.data);

            }).catch(err => {

            })


        // standard

        axios.get(`${url}/cars/get-car-list-paginate`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                limit: 3,
                category: "66b47a35f101b5ec2dfac68f"
            }
        })
            .then(res => {
                setStandardCars(res.data.data.docs)
            }).catch(err => {

            })


        // eghtesadi
        axios.get(`${url}/cars/get-car-list-paginate`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                limit: 3,
                category: "66f1285efbf968e3e6dd5a81"
            }
        })
            .then(res => {
                setEghtesadiCars(res.data.data.docs)
            }).catch(err => {

            })

        //cip
        axios.get(`${url}/cars/get-car-list-paginate`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
            params: {
                limit: 3,
                category: "66b47a35f101b5ec2dfac691"
            }
        })
            .then(res => {
                setCipCars(res.data.data.docs)
            }).catch(err => {

            })

    }, []);


    const openCarTypeMenu = () => {
        setCarTypeMenu(!carTypeMenu);
        setCategoryLoading(true)
        axios.get(`${url}/car-category/v1/get-car-category-list`)
            .then(res => {
                setAllCarTypes(res.data.data);
                setCategoryLoading(false)
            }).catch(err => {
                if (err?.response?.status !== 200)
                    setCategoryLoading(true)
            })
    }


    const handleSubmit = () => {
        const requestData = {
            origin_city: locationId,
            destination_city: destanationId,
            travel_date: gregorianDate,
            travel_hour_time: time,
            car_type_id: selectCartype._id,
            passenger_count: counter
        };

        setAcceptTravel(true)
        axios.post(`${url}/travel/v1/create-new-travel`, requestData, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => {
                // console.log()
                navigate(`/passenger/${response?.data?.data?._id}`);
                window.scrollTo(0, 0);
                dispatch(setTravel({
                    travelId: Cookies.get("TravelId"),
                    origin: locationId,
                    destination: destanationId,
                }));
                setAcceptTravel(false);
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setIsModalOpen(true)
                    if (error?.response) {
                        if (error.response.status === 401) {
                            notification.error({
                                message: "ابتدا وارد حساب کاربری شوید",
                                description: "",
                                placement: 'topRight',
                            });
                        } else {
                            notification.error({
                                message: error?.response?.data?.message,
                                description: "",
                                placement: 'topRight',
                            });
                        }
                    }
                    setAcceptTravel(false);
                } else {
                    console.error("Error:", error);
                    notification.error({
                        message: error?.response?.data?.message,
                        description: "",
                        placement: 'topRight',
                    });
                    setAcceptTravel(false);
                }

                if (error?.message === "Network Error") {
                    setAcceptTravel(true)
                    notification.error({
                        message: "خطا در اتصال به اینترنت"
                    })
                }
            });
    };
    const incrementCounter = () => {
        if (counter !== 3)
            setCounter(counter + 1);
        if (counter === 3) {
            notification.error({
                message: "بیشتر از ۳ مسافر ممکن نمیباشد !"
            })
        }
    };
    const decrementCounter = () => {
        if (counter > 1) {
            setCounter(counter - 1);
        }
    };


    const handleOpenlocation = () => {
        setOpenLocation(!openLocation);
        document.getElementById("tripManagement").scrollIntoView({ behavior: "smooth" });
        setopenDestination(false);
        setShowCartype(false);
    };

    const handleOpenDestination = () => {
        document.getElementById("tripManagement").scrollIntoView({ behavior: "smooth" });
        setopenDestination(!openDestination);
        setOpenLocation(false);
        setShowCartype(false);
    };

    const HandleShowtype = () => {
        setShowCartype(!showCartype);
        setopenDestination(false);
        setOpenLocation(false);
    };

    const handleDateChange = (date) => {
        setgregorianDate(date.convert(gregorian, gregorian_en).format("YYYY-MM-DD"));
    };


    const handleTimePicker = () => {
        setOpenTimePicker(!openTimePicker);
    }


    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const minutes = [0, 15, 30, 45];


    const handleHourClick = (hour) => {
        setSelectedHour(hour);
    };

    const handleMinuteClick = (minute) => {
        setSelectedMinute(minute);
    };

    const handleTime = () => {
        if (selectedHour !== null && selectedMinute !== null) {
            setTime(`${selectedHour.toString().padStart(2, '0')}:${selectedMinute.toString().padStart(2, '0')}`);
            setOpenTimePicker(false);
        }
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px 0px 0px 0px',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            borderRadius: '10px',
            outline: 'none',
            border: 'none'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
    };

    const handleCancel = () => {
        window.location.reload();
        setIsModalOpen(false);
    };


    return (
        <>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCancel}
                style={customStyles}
                className="modalMobile"
            >
                <button className="custom-modal-close" onClick={handleCancel}>
                    &times;
                </button>
                {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : null}
            </Modal>


            <div
                className=" md:w-full tablet10:w-full relative flex justify-center mt-[60px] mx-5 tablet10:mx-0 md:mx-0">

                <div
                    className=" tablet10:shadow-none md:h-full md:!max-w-full tablet10:w-full tablet10:h-full tablet10:bg-transparent md:bg-transparent md:shadow-none shadow-md bg-opacity-40 flex w-full max-w-fit tablet10:max-w-full h-[110px] bg-transparent rounded-[20px] justify-center items-center bg-white ">
                    <div
                        id="tripManagement"
                        className="w-full flex items-center justify-center px-10 gap-x-3 gap-y-2 md:p-0 p-4 md:flex-col md:w-full md:justify-center md:items-center tablet10:flex-col tablet10:justify-center tablet10:items-center tablet10:w-full"
                    >
                        <div
                            className={`input-container ${inputVal ? "!border-black" : ""} ${openLocation ? "!border-black" : ""} `}>
                            <div className="w-[200px] tablet10:!w-full md:!w-full font-medium h-full overscroll-none">
                                <div
                                    onClick={handleOpenlocation}
                                    className="cursor-pointer gap-2 h-full bg-transparent flex w-full p-2 items-center justify-between rounded">
                                    <div
                                        type="text"
                                        className="flex text-nowrap items-center bg-transparent w-full outline-none h-full placeholder:text-gray-500"
                                    >
                                        {inputVal ? inputVal : 'انتخاب مبدا'}
                                    </div>
                                    <div>
                                        <BiChevronDown
                                            className={`transform transition-transform duration-300 ${openLocation ? 'rotate-180' : 'rotate-0'}`}
                                            size={24} />
                                    </div>
                                </div>
                                <div
                                    className="rounded-xl bg-white relative w-full z-10 mt-[4px] shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
                                    <div
                                        className={` max-h-[400px] ${openLocation ? "block" : "hidden"}`}>
                                        <div className="w-full justify-between flex items-center pl-3 pt-2">
                                            <input
                                                value={searchVal}
                                                onChange={(e) => setSearchVal(e.target.value)}
                                                type="text"
                                                placeholder="جستجو"
                                                className="placeholder:text-gray-500 w-full p-2 outline-none"
                                            />
                                            <AiOutlineSearch size={18} className="text-gray-500" />
                                        </div>
                                        <ul className="overflow-y-auto max-h-[260px]">
                                            {cityName?.map((cityItem, index) => (
                                                <li
                                                    onClick={() => {
                                                        if (cityItem?.name !== inputVal) {
                                                            setInputVal(cityItem?.name);
                                                            setlocationId(cityItem._id)
                                                        }
                                                        setOpenLocation(!openLocation);
                                                    }}
                                                    key={index}
                                                    className={`pt-2 pb-2 cursor-pointer p-2 hover:bg-blue-500 text-black hover:text-white ${cityItem?.name.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase()) ? "block" : "hidden"}`}
                                                >
                                                    <p className="flex gap-2 items-center">
                                                        <IoLocationOutline size={20} />
                                                        {cityItem.name}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`input-container ${inputValDestination ? "!border-black" : ""} ${openLocation ? "!border-black" : ""} `}>
                            <div
                                className={`!w-[200px] !min-w-[150px] tablet10:!w-full md:!w-full font-medium h-full overscroll-none `}>
                                <div
                                    onClick={handleOpenDestination}
                                    className={`cursor-pointer p-2 h-full bg-transparent flex w-full items-center justify-between rounded`}>
                                    <div
                                        className={`flex items-center placeholder:text-gray-500 bg-transparent w-full outline-none h-full`}
                                    >
                                        {inputValDestination ? inputValDestination : 'انتخاب مقصد'}
                                    </div>
                                    <div>
                                        <BiChevronDown
                                            className={`${openLocation ? 'md:hidden' : ''} transform transition-transform duration-300 ${openDestination ? 'rotate-180' : 'rotate-0'}`}
                                            size={24} />
                                    </div>
                                </div>
                                <div
                                    className=" rounded-xl bg-white w-full relative z-10 mt-[4px] shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
                                    <div
                                        className={`max-h-[400px] ${openDestination ? "block" : "hidden"}`}>
                                        <div
                                            className="w-full justify-between flex items-center pl-3 pt-2 overscroll-none">
                                            <input
                                                value={searchDestination}
                                                onChange={(e) => setSearchDestination(e.target.value)}
                                                type="text"
                                                placeholder="جستجو"
                                                className="placeholder:text-gray-500 w-full p-2 outline-none"
                                            />
                                            <AiOutlineSearch size={18} className="text-gray-500" />
                                        </div>
                                        <ul className="overflow-y-auto max-h-[260px]">
                                            {cityName?.map((cityItem, index) => (
                                                <li
                                                    onClick={() => {
                                                        if (cityItem?.name !== inputValDestination) {
                                                            setInputValDestination(cityItem?.name);
                                                            setDestanationId(cityItem._id)
                                                        }
                                                        setopenDestination(!openDestination);
                                                    }}
                                                    key={index}
                                                    className={`pt-2 pb-2 cursor-pointer p-2 hover:bg-blue-500 text-black hover:text-white ${cityItem?.name.toLocaleLowerCase().includes(searchDestination.toLocaleLowerCase()) ? "block" : "hidden"}`}
                                                >
                                                    <p className="flex gap-2 items-center">
                                                        <IoLocationOutline size={20} />
                                                        {cityItem.name}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:!w-[90%] gap-x-3 flex w-auto ">
                            <div className="md:col-span-1 !w-full ">
                                <div
                                    className={`input-container !w-[150px] tablet10:!w-[200px] md:!w-[100%] ${openLocation ? "!border-black" : ""}  ${gregorianDate ? "!border-black" : ""}`}>
                                    {/*<BsCalendar className="input-Icon"/>*/}
                                    <DatePicker
                                        animations={[
                                            opacity(),
                                            transition({
                                                from: 40,
                                                transition: "all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)",
                                            }),
                                        ]}
                                        calendar={persian}
                                        locale={persian_fa}
                                        calendarPosition="bottom-right"
                                        placeholder="تاریخ سفر"
                                        className=" md:rmdp-mobile cursor-pointer"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            border: "none",
                                            outline: "none",
                                            backgroundColor: "transparent",
                                        }}
                                        // format="YYYY-MM-DD"
                                        format="DD MMMM"
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="md:flex md:flex-col cursor-pointer">
                                    <div onClick={handleTimePicker}
                                        className={`input-container-forSelectCar md:!w-[100%] tablet10:!200px ${time !== 'انتخاب ساعت' ? "!border-black" : ""}`}>
                                        <div
                                            className=" ml-2 mr-2 items-center w-full h-full flex text-black justify-between">
                                            <div>
                                                <p>{time}</p>
                                            </div>
                                            <div>
                                                <div>
                                                    <BiChevronDown
                                                        className={` ${openLocation || openDestination ? 'md:hidden' : ''} transform transition-transform duration-300 ${openTimePicker ? 'rotate-180' : 'rotate-0'}`}
                                                        size={24} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative">
                                    {openTimePicker && (
                                        <div
                                            className="shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)] mt-1  gap-4 p-2 rounded-[10px] bg-white h-auto absolute md:w-full w-full">
                                            <div className="flex gap-x-3 w-full justify-between pl-4 pr-4">
                                                <div className="w-full flex flex-col items-center">
                                                    <p className="m-1">دقیقه</p>
                                                    <ul className="list-none p-0 m-0 w-full">
                                                        {minutes.map((minute) => (
                                                            <li
                                                                key={minute}
                                                                className={`hover:text-black hover:bg-gray-200 hover:rounded-lg cursor-pointer p-1 items-center flex flex-col w-full ${selectedMinute === minute ? 'bg-[#4F48EC] text-white rounded-lg' : 'bg-white text-black'}`}
                                                                onClick={() => handleMinuteClick(minute)}
                                                            >
                                                                {minute.toString().padStart(2, '0')}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="w-full flex flex-col items-center">
                                                    <p className="m-1">ساعت</p>
                                                    <ul className="list-none p-0 m-0 max-h-40 w-full overflow-y-scroll">
                                                        {hours.map((hour) => (
                                                            <li
                                                                key={hour}
                                                                className={` hover:bg-gray-200 hover:rounded-lg hover:text-black cursor-pointer items-center flex flex-col gap-2 p-1 ${selectedHour === hour ? 'bg-[#4F48EC] text-white rounded-lg' : 'bg-white text-black'}`}
                                                                onClick={() => handleHourClick(hour)}
                                                            >
                                                                {hour.toString().padStart(2, '0')}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="flex justify-center">
                                                <button onClick={handleTime}
                                                    className="bg-[#4F48EC] text-white p-2 rounded mt-4 w-[90%]">
                                                    ثبت
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:w-[90%] flex gap-4">
                            <div className="col-span-1" onClick={() => openCarTypeMenu()}>
                                <div className="md:flex md:flex-col cursor-pointer">
                                    <div onClick={HandleShowtype}
                                        className={`input-container-forSelectCar md:!w-[100%] tablet10:!w-[200px] ${selectCartype.name ? '!border-black' : ''}`}>
                                        <div
                                            className=" ml-2 mr-2 items-center w-full h-full flex text-black justify-between">
                                            <div><p>
                                                {selectCartype.name === undefined ? "نوع خودرو" : selectCartype.name}
                                            </p></div>
                                            <div>
                                                <div>
                                                    <BiChevronDown
                                                        className={` ${openLocation || openDestination ? 'md:hidden' : ''} transform transition-transform duration-300 ${showCartype ? 'rotate-180' : 'rotate-0'}`}
                                                        size={24} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`relative`}>

                                        {
                                            carTypeMenu && (
                                                <div
                                                    className="shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)] mt-1 flex flex-col gap-4 px-2 py-4 rounded-[10px] bg-white h-auto absolute md:w-full w-full">
                                                    {
                                                        !categoryLoading ? (
                                                            <>
                                                                {
                                                                    allCarTypes?.map((item, index) => (
                                                                        <>
                                                                            {
                                                                                item.name === "VIP" && (
                                                                                    <div className="flex flex-col ">
                                                                                        <p key={index}
                                                                                            onClick={() => setSelectCartype(item)}>{item.name}</p>
                                                                                        <div
                                                                                            className="flex items-center gap-x-2 text-[12px]">{
                                                                                                vipCars.length ?
                                                                                                    vipCars.map(value => (
                                                                                                        <p>{value.name}</p>
                                                                                                    ))
                                                                                                    :
                                                                                                    <p>---</p>
                                                                                            }</div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {
                                                                                item.name === "CIP" && (
                                                                                    <div className="flex flex-col ">
                                                                                        <p key={index}
                                                                                            onClick={() => setSelectCartype(item)}>{item.name}</p>
                                                                                        <div
                                                                                            className="flex items-center gap-x-2 text-[12px]">{
                                                                                                cipCars.length ?
                                                                                                    cipCars.map(value => (
                                                                                                        <p>{value.name} </p>
                                                                                                    ))
                                                                                                    :
                                                                                                    <p>---</p>
                                                                                            }</div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {
                                                                                item.name === "میان رده" && (
                                                                                    <div className="flex flex-col ">
                                                                                        <p key={index}
                                                                                            onClick={() => setSelectCartype(item)}>{item.name}</p>
                                                                                        <div
                                                                                            className="flex items-center gap-x-2 text-[12px]">{
                                                                                                mianRadeCars.length > 0 ?
                                                                                                    mianRadeCars.map(value => (
                                                                                                        <p>{value.name}</p>))
                                                                                                    :
                                                                                                    <p>---</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {
                                                                                item.name === "اقتصادی" && (
                                                                                    <div className="flex flex-col ">
                                                                                        <p key={index}
                                                                                            onClick={() => setSelectCartype(item)}>{item.name}</p>
                                                                                        <div
                                                                                            className="flex items-center gap-x-2 text-[12px]">{
                                                                                                eghtesadiCars.length > 0 ?
                                                                                                    eghtesadiCars.map(value => (
                                                                                                        <p>{value.name} </p>
                                                                                                    ))
                                                                                                    :
                                                                                                    <p>---</p>
                                                                                            }</div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {
                                                                                item.name === "STANDARD" && (
                                                                                    <div className="flex flex-col ">
                                                                                        <p key={index}
                                                                                            onClick={() => setSelectCartype(item)}>{item.name}</p>
                                                                                        <div
                                                                                            className="flex items-center gap-x-2 text-[12px]">{
                                                                                                standardCard.length ?
                                                                                                    standardCard.map(value => (
                                                                                                        <p>{value.name} </p>
                                                                                                    ))
                                                                                                    :
                                                                                                    <p>---</p>
                                                                                            }</div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </>
                                                                    ))
                                                                }
                                                            </>
                                                        ) : (
                                                            <div
                                                                className="w-full flex justify-center text-[20px] animate-spin">
                                                                <TbLoader2 />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div
                                    className=" gap-2 justify-center input-container-counter md:!w-full flex items-center tablet10:!200px">
                                    <button
                                        onClick={incrementCounter}
                                        className=" w-[24px] h-[24px] bg-[#4F48EC] text-white text-16 font-semibold rounded-[5px] flex items-center justify-center"
                                    >
                                        +
                                    </button>
                                    <p className="text-center flex gap-2">
                                        <span className="">{counter}</span>
                                        مسافر
                                    </p>
                                    <button
                                        onClick={decrementCounter}
                                        className=" w-[24px] h-[24px] bg-[#4F48EC] text-white text-16 font-semibold rounded-[5px] flex items-center justify-center"
                                    >
                                        -
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button onClick={handleSubmit}
                            className=" items-center w-[120px] px-5 flex justify-center md:w-[90%] md:h-[50px] bg-[#4F48EC] h-[48px] rounded-[10px] tablet10:mt-5 tablet10:w-[60%]">
                            {accpetTravel ? (
                                <Loading />
                            ) : (
                                <p className="text-nowrap"
                                    style={{ color: "white", fontSize: "16px", fontWeight: "500" }}>ثبت درخواست</p>
                            )}
                        </button>
                    </div>
                </div>
            </div>

            {/* <div className="border-2 border-blue-400 w-[80%] mx-auto mt-[40px] rounded-xl">
                <p>
                    اعتبار شما
                </p>
            </div> */}
            {/* <div className=" p-0 m-0  top-0 right-0">
                        <DotLottieReact
                            src="https://lottie.host/afa64217-3267-4db2-b3fe-eafca893c999/gb24OZomxn.lottie"
                            loop
                            autoplay
                        />
                    </div> */}
            <Details />
        </>
    );
};




export default Body;



export function modal() {


    return (
        <div className="report-modal fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity">
            <div className="flex min-h-full p-3">
                <div className="rounded-lg bg-white w-[60%] md:w-[88%] p-8 md:p-0 md:pt-8 md:pb-8 m-auto gap-5">
                </div>
            </div>
        </div>

    )
}

