import React, { useRef, useState } from 'react'
import Header2 from '../header/Header2'
import Modal from "react-modal";
import { useContext } from 'react';
import { StateApp } from '../context/Context';
import LoginRegister from '../Login/LoginRegister';
import SuccessLogin from '../Modal/Success Login';
import { FaClosedCaptioning, FaDeleteLeft } from 'react-icons/fa6';
import { FaTicketAlt } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { MdContactSupport } from "react-icons/md";
import { useEffect } from 'react';
import Footer from '../footer/Footer';
import { IoAlertCircle } from "react-icons/io5";
import axios from 'axios';
import { url } from '../url';
import { message } from 'antd';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const SupportPage = () => {
  const token = localStorage.getItem("accessToken");
  const { loading, setLoading, openLogin, showSuccessLogin, updateUserName_header, setUpdateUserName_header } = useContext(StateApp);
  const [openTicket, setOpenTicket] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ticketSubject, setTicketSubject] = useState('')
  const [description, setDescription] = useState("")

  const navigate = useNavigate(null)

  // const ticketInput = useRef(null);

  // useEffect(() => {
  //   if (ticketInput.current) {
  //     if (ticketSubject === "هیچکدام") {
  //       ticketInput.current.style.transitionDuration = "1s";
  //       ticketInput.current.style.width = "100%";
  //       ticketInput.current.style.height = "auto";
  //     } if (ticketSubject !== "هیچکدام" || ticketSubject === null) {
  //       ticketInput.current.style.transitionDuration = "1s";
  //       ticketInput.current.style.minWidth = "0px";
  //       ticketInput.current.style.width = "0px";
  //       ticketInput.current.style.overflow = "hidden";
  //     }
  //   }
  // }, [ticketSubject]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      outline: 'none',
      border: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };

  const ticketStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '20px 60px',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      backgroundColor: "white",
      height: "450px",
      outline: 'none',
      border: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };

  const sendTicket = () => {
    axios.post(`${url}/tickets/v1/add-ticket-passenger`, {
      subject: ticketSubject,
      description: description
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(res => {
      setTicketSubject("")
      setDescription("")
      setOpenTicket(false)
      notification.success({
        message: "تیکت با موفقیت فرستاده شد",
        description: "",
        placement: 'topRight',
      });
    }).catch(err => {
      setOpenTicket(false)
      notification.error({
        message: err.response?.data?.message,
        description: "",
        placement: 'topRight',
      });
    })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='w-screen h-screen flex justify-between flex-col'>
      <div className='md:hidden'>
        <Header2 />
      </div>

      <div className='w-[80%] mx-auto'>
        <h2 className='text-black mx-auto mt-10 text-[24px] border-b-[1px] border-amber-500 pb-2 flex items-center'><MdContactSupport className='text-amber-500' /> پشتیبانی </h2>

        <div className='flex items-center md:gap-y-5 md:flex-col justify-center mt-[100px] gap-x-10 text-white'>
          <div onClick={() => token === null ? setIsModalOpen(true) : setOpenTicket(true)}
            className='w-[300px] h-[190px] bg-darkBlue flex flex-col items-center justify-center text-[20px] rounded-[20px] 
            hover:tracking-widest duration-[0.4s] cursor-pointer hover:bg-amber-500'>
            <p>باز کردن تیکت</p>
            <FaTicketAlt className='text-[60px]' />
          </div>


          <div onClick={() => { token === null ? setIsModalOpen(true) : navigate("/userTickets") }} className='w-[300px] h-[190px] bg-darkBlue flex flex-col items-center justify-center text-[20px] rounded-[20px]
            hover:tracking-widest duration-[0.4s] cursor-pointer hover:bg-amber-500'>
            <p>تیکت های من</p>
            <MdSupportAgent className='text-[60px]' />
          </div>
        </div>


        <p className='mt-[100px] pr-5 border-r-[2px] border-amber-500'>
          به صفحه پشتیبانی مارانکو خوش آمدید!

          ما در مارانکو به سفرهای شما اهمیت می‌دهیم و هدفمان این است که هر لحظه از سفر شما را به تجربه‌ای خوشایند تبدیل کنیم. اگر در طول مسیر با چالشی مواجه شدید یا سوالی دارید، ما اینجا هستیم تا به شما کمک کنیم.

          تیم پشتیبانی ما آماده است تا هرگونه مشکل یا نگرانی شما را بشنود و با دقت به آن رسیدگی کند. از حوادث ناگهانی گرفته تا سوالات درباره خدمات ما، ما همیشه در کنار شما هستیم. تجربه شما برای ما ارزشمند است و هر تیکت یا پیام شما را با جدیت بررسی می‌کنیم.

          از انتخاب مارانکو سپاسگزاریم و منتظر شنیدن از شما هستیم!

        </p>
      </div>


      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        style={customStyles}
        className="modalMobile"
      >

        <div className=''>
          <button className="custom-modal-close" onClick={handleCancel}>
            &times;
          </button>
          {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : <LoginRegister />}
        </div>
      </Modal>

      {
        openTicket && (
          <Modal
            isOpen={openTicket}
            onRequestClose={handleCancel}
            style={ticketStyle}
            className="modalMobile w-[700px] md:w-[300px]"
          >

            <div className=''>
              <button className="custom-modal-close" onClick={() => setOpenTicket(false)}>
                &times;
              </button>
              <br /><h2 className='mb-10 font-bold'>باز کردن تیکت</h2>
              <label className='text-gray-700 flex items-center gap-x-2'><div title='گزینه مرتبط با مشکل خود را انتخاب کنید' className='cursor-pointer'><IoAlertCircle className='text-amber-500' /></div>لطفا موضوع مرتبط رو انتخاب کنید:</label>
              <div className='pt-5'>
                <select value={ticketSubject} onChange={(e) => setTicketSubject(e.target.value)} className=' outline-none cursor-pointer w-full border-b-[1px] border-amber-500'>
                  <option value=''></option>
                  <option value='تاخیر'>تاخیر</option>
                  <option value='تصادف'>تصادف</option>
                  <option value='مشکل در پرداخت هزینه'>مشکل در پرداخت هزینه</option>
                  <option value='تیکت دلخواه'>تیکت دلخواه</option>
                </select>
              </div>

              <div className={` mt-[80px] `}>
                <p className='mb-5 text-gray-700 text-nowrap flex items-center gap-x-2'><div title='سوالات یا مشکلات خود را برای ما جهت پیگیری سریع بنویسید' className='cursor-pointer'><IoAlertCircle className='text-amber-500' /></div>لطفا توضیحات خود را بنویسد:</p>
                <input value={description} onChange={(e) => setDescription(e.target.value)} type='text' className={`border-b-[1px] border-amber-500 outline-none w-full`} />
              </div>

              <div className='w-full flex justify-center items-center mt-10'>
                <button
                  onClick={sendTicket}
                  className='mx-auto font-bold text-amber-500 rounded-lg border-[1px] hover:bg-amber-500 hover:text-white duration-[0.4s] border-amber-500 px-5 py-2'>ثبت تیکت</button>
              </div>
            </div>
          </Modal>
        )
      }
      <div className='mt-[100px]'>
        <Footer />
      </div>
    </div>
  )
}

export default SupportPage
